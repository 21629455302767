/**
 * @fileoverview gRPC-Web generated client stub for gateway_service
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var appointment_service_pb = require('./appointment_service_pb.js')

var billing_service_pb = require('./billing_service_pb.js')

var contract_service_pb = require('./contract_service_pb.js')

var customer_service_pb = require('./customer_service_pb.js')

var customer_support_service_pb = require('./customer_support_service_pb.js')

var dealer_service_pb = require('./dealer_service_pb.js')

var dealership_service_pb = require('./dealership_service_pb.js')

var demo_service_pb = require('./demo_service_pb.js')

var document_service_pb = require('./document_service_pb.js')

var domain_pb = require('./domain_pb.js')

var fleet_service_pb = require('./fleet_service_pb.js')

var inspection_pb = require('./inspection_pb.js')

var insurance_service_pb = require('./insurance_service_pb.js')

var nexus_service_pb = require('./nexus_service_pb.js')

var notification_service_pb = require('./notification_service_pb.js')

var payment_service_pb = require('./payment_service_pb.js')

var spre_pb = require('./spre_pb.js')

var subscription_service_pb = require('./subscription_service_pb.js')

var vehicle_pb = require('./vehicle_pb.js')

var vehicle_listing_service_pb = require('./vehicle_listing_service_pb.js')

var web_pb = require('./web_pb.js')

var telematics_service_pb = require('./telematics_service_pb.js')
const proto = {};
proto.gateway_service = require('./gateway_service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.TelematicsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.TelematicsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.telematics_service.CommandVehicleRequest,
 *   !proto.telematics_service.CommandVehicleResponse>}
 */
const methodDescriptor_TelematicsService_CommandVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.TelematicsService/CommandVehicle',
  grpc.web.MethodType.UNARY,
  telematics_service_pb.CommandVehicleRequest,
  telematics_service_pb.CommandVehicleResponse,
  /**
   * @param {!proto.telematics_service.CommandVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.CommandVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.telematics_service.CommandVehicleRequest,
 *   !proto.telematics_service.CommandVehicleResponse>}
 */
const methodInfo_TelematicsService_CommandVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  telematics_service_pb.CommandVehicleResponse,
  /**
   * @param {!proto.telematics_service.CommandVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.CommandVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.telematics_service.CommandVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.telematics_service.CommandVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.telematics_service.CommandVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.TelematicsServiceClient.prototype.commandVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.TelematicsService/CommandVehicle',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_CommandVehicle,
      callback);
};


/**
 * @param {!proto.telematics_service.CommandVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.telematics_service.CommandVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.TelematicsServicePromiseClient.prototype.commandVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.TelematicsService/CommandVehicle',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_CommandVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.telematics_service.GetTelematicsRequest,
 *   !proto.telematics_service.GetTelematicsResponse>}
 */
const methodDescriptor_TelematicsService_GetTelematics = new grpc.web.MethodDescriptor(
  '/gateway_service.TelematicsService/GetTelematics',
  grpc.web.MethodType.UNARY,
  telematics_service_pb.GetTelematicsRequest,
  telematics_service_pb.GetTelematicsResponse,
  /**
   * @param {!proto.telematics_service.GetTelematicsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.GetTelematicsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.telematics_service.GetTelematicsRequest,
 *   !proto.telematics_service.GetTelematicsResponse>}
 */
const methodInfo_TelematicsService_GetTelematics = new grpc.web.AbstractClientBase.MethodInfo(
  telematics_service_pb.GetTelematicsResponse,
  /**
   * @param {!proto.telematics_service.GetTelematicsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.GetTelematicsResponse.deserializeBinary
);


/**
 * @param {!proto.telematics_service.GetTelematicsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.telematics_service.GetTelematicsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.telematics_service.GetTelematicsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.TelematicsServiceClient.prototype.getTelematics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.TelematicsService/GetTelematics',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_GetTelematics,
      callback);
};


/**
 * @param {!proto.telematics_service.GetTelematicsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.telematics_service.GetTelematicsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.TelematicsServicePromiseClient.prototype.getTelematics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.TelematicsService/GetTelematics',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_GetTelematics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.telematics_service.StreamTelematicsRequest,
 *   !proto.telematics_service.Telematics>}
 */
const methodDescriptor_TelematicsService_StreamTelematics = new grpc.web.MethodDescriptor(
  '/gateway_service.TelematicsService/StreamTelematics',
  grpc.web.MethodType.SERVER_STREAMING,
  telematics_service_pb.StreamTelematicsRequest,
  telematics_service_pb.Telematics,
  /**
   * @param {!proto.telematics_service.StreamTelematicsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.Telematics.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.telematics_service.StreamTelematicsRequest,
 *   !proto.telematics_service.Telematics>}
 */
const methodInfo_TelematicsService_StreamTelematics = new grpc.web.AbstractClientBase.MethodInfo(
  telematics_service_pb.Telematics,
  /**
   * @param {!proto.telematics_service.StreamTelematicsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  telematics_service_pb.Telematics.deserializeBinary
);


/**
 * @param {!proto.telematics_service.StreamTelematicsRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.telematics_service.Telematics>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.TelematicsServiceClient.prototype.streamTelematics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.TelematicsService/StreamTelematics',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_StreamTelematics);
};


/**
 * @param {!proto.telematics_service.StreamTelematicsRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.telematics_service.Telematics>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.TelematicsServicePromiseClient.prototype.streamTelematics =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.TelematicsService/StreamTelematics',
      request,
      metadata || {},
      methodDescriptor_TelematicsService_StreamTelematics);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.AppointmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.AppointmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.CreateAppointmentRequest,
 *   !proto.appointment_service.CreateAppointmentResponse>}
 */
const methodDescriptor_AppointmentService_CreateAppointment = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/CreateAppointment',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.CreateAppointmentRequest,
  appointment_service_pb.CreateAppointmentResponse,
  /**
   * @param {!proto.appointment_service.CreateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.CreateAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.CreateAppointmentRequest,
 *   !proto.appointment_service.CreateAppointmentResponse>}
 */
const methodInfo_AppointmentService_CreateAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.CreateAppointmentResponse,
  /**
   * @param {!proto.appointment_service.CreateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.CreateAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.CreateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.CreateAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.CreateAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.createAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/CreateAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_CreateAppointment,
      callback);
};


/**
 * @param {!proto.appointment_service.CreateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.CreateAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.createAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/CreateAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_CreateAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.ListAppointmentsRequest,
 *   !proto.appointment_service.ListAppointmentsResponse>}
 */
const methodDescriptor_AppointmentService_ListAppointments = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/ListAppointments',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.ListAppointmentsRequest,
  appointment_service_pb.ListAppointmentsResponse,
  /**
   * @param {!proto.appointment_service.ListAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.ListAppointmentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.ListAppointmentsRequest,
 *   !proto.appointment_service.ListAppointmentsResponse>}
 */
const methodInfo_AppointmentService_ListAppointments = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.ListAppointmentsResponse,
  /**
   * @param {!proto.appointment_service.ListAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.ListAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.ListAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.ListAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.listAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_ListAppointments,
      callback);
};


/**
 * @param {!proto.appointment_service.ListAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.ListAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.listAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/ListAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_ListAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.UpdateAppointmentRequest,
 *   !proto.appointment_service.UpdateAppointmentResponse>}
 */
const methodDescriptor_AppointmentService_UpdateAppointment = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/UpdateAppointment',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.UpdateAppointmentRequest,
  appointment_service_pb.UpdateAppointmentResponse,
  /**
   * @param {!proto.appointment_service.UpdateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.UpdateAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.UpdateAppointmentRequest,
 *   !proto.appointment_service.UpdateAppointmentResponse>}
 */
const methodInfo_AppointmentService_UpdateAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.UpdateAppointmentResponse,
  /**
   * @param {!proto.appointment_service.UpdateAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.UpdateAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.UpdateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.UpdateAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.UpdateAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.updateAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/UpdateAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_UpdateAppointment,
      callback);
};


/**
 * @param {!proto.appointment_service.UpdateAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.UpdateAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.updateAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/UpdateAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_UpdateAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.CancelAppointmentRequest,
 *   !proto.appointment_service.CancelAppointmentResponse>}
 */
const methodDescriptor_AppointmentService_CancelAppointment = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/CancelAppointment',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.CancelAppointmentRequest,
  appointment_service_pb.CancelAppointmentResponse,
  /**
   * @param {!proto.appointment_service.CancelAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.CancelAppointmentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.CancelAppointmentRequest,
 *   !proto.appointment_service.CancelAppointmentResponse>}
 */
const methodInfo_AppointmentService_CancelAppointment = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.CancelAppointmentResponse,
  /**
   * @param {!proto.appointment_service.CancelAppointmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.CancelAppointmentResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.CancelAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.CancelAppointmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.CancelAppointmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.cancelAppointment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/CancelAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_CancelAppointment,
      callback);
};


/**
 * @param {!proto.appointment_service.CancelAppointmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.CancelAppointmentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.cancelAppointment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/CancelAppointment',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_CancelAppointment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.GetAppointmentCheckoutStatusRequest,
 *   !proto.appointment_service.GetAppointmentCheckoutStatusResponse>}
 */
const methodDescriptor_AppointmentService_GetAppointmentCheckoutStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/GetAppointmentCheckoutStatus',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.GetAppointmentCheckoutStatusRequest,
  appointment_service_pb.GetAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.GetAppointmentCheckoutStatusRequest,
 *   !proto.appointment_service.GetAppointmentCheckoutStatusResponse>}
 */
const methodInfo_AppointmentService_GetAppointmentCheckoutStatus = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.GetAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.GetAppointmentCheckoutStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.GetAppointmentCheckoutStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.getAppointmentCheckoutStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentCheckoutStatus,
      callback);
};


/**
 * @param {!proto.appointment_service.GetAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.GetAppointmentCheckoutStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.getAppointmentCheckoutStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentCheckoutStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.AppointmentKeyHandOffRequest,
 *   !proto.appointment_service.AppointmentKeyHandOffResponse>}
 */
const methodDescriptor_AppointmentService_AppointmentKeyHandOff = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/AppointmentKeyHandOff',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.AppointmentKeyHandOffRequest,
  appointment_service_pb.AppointmentKeyHandOffResponse,
  /**
   * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.AppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.AppointmentKeyHandOffRequest,
 *   !proto.appointment_service.AppointmentKeyHandOffResponse>}
 */
const methodInfo_AppointmentService_AppointmentKeyHandOff = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.AppointmentKeyHandOffResponse,
  /**
   * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.AppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.AppointmentKeyHandOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.AppointmentKeyHandOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.appointmentKeyHandOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/AppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_AppointmentKeyHandOff,
      callback);
};


/**
 * @param {!proto.appointment_service.AppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.AppointmentKeyHandOffResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.appointmentKeyHandOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/AppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_AppointmentKeyHandOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.GetAppointmentKeyHandOffRequest,
 *   !proto.appointment_service.GetAppointmentKeyHandOffResponse>}
 */
const methodDescriptor_AppointmentService_GetAppointmentKeyHandOff = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/GetAppointmentKeyHandOff',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.GetAppointmentKeyHandOffRequest,
  appointment_service_pb.GetAppointmentKeyHandOffResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.GetAppointmentKeyHandOffRequest,
 *   !proto.appointment_service.GetAppointmentKeyHandOffResponse>}
 */
const methodInfo_AppointmentService_GetAppointmentKeyHandOff = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.GetAppointmentKeyHandOffResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentKeyHandOffResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.GetAppointmentKeyHandOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.GetAppointmentKeyHandOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.getAppointmentKeyHandOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentKeyHandOff,
      callback);
};


/**
 * @param {!proto.appointment_service.GetAppointmentKeyHandOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.GetAppointmentKeyHandOffResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.getAppointmentKeyHandOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentKeyHandOff',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentKeyHandOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.GetAppointmentPickupDateRequest,
 *   !proto.appointment_service.GetAppointmentPickupDateResponse>}
 */
const methodDescriptor_AppointmentService_GetAppointmentPickupDate = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/GetAppointmentPickupDate',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.GetAppointmentPickupDateRequest,
  appointment_service_pb.GetAppointmentPickupDateResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentPickupDateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.GetAppointmentPickupDateRequest,
 *   !proto.appointment_service.GetAppointmentPickupDateResponse>}
 */
const methodInfo_AppointmentService_GetAppointmentPickupDate = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.GetAppointmentPickupDateResponse,
  /**
   * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetAppointmentPickupDateResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.GetAppointmentPickupDateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.GetAppointmentPickupDateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.getAppointmentPickupDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentPickupDate',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentPickupDate,
      callback);
};


/**
 * @param {!proto.appointment_service.GetAppointmentPickupDateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.GetAppointmentPickupDateResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.getAppointmentPickupDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetAppointmentPickupDate',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetAppointmentPickupDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.ListCompletedAppointmentsRequest,
 *   !proto.appointment_service.ListCompletedAppointmentsResponse>}
 */
const methodDescriptor_AppointmentService_ListCompletedAppointments = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/ListCompletedAppointments',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.ListCompletedAppointmentsRequest,
  appointment_service_pb.ListCompletedAppointmentsResponse,
  /**
   * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.ListCompletedAppointmentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.ListCompletedAppointmentsRequest,
 *   !proto.appointment_service.ListCompletedAppointmentsResponse>}
 */
const methodInfo_AppointmentService_ListCompletedAppointments = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.ListCompletedAppointmentsResponse,
  /**
   * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.ListCompletedAppointmentsResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.ListCompletedAppointmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.ListCompletedAppointmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.listCompletedAppointments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/ListCompletedAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_ListCompletedAppointments,
      callback);
};


/**
 * @param {!proto.appointment_service.ListCompletedAppointmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.ListCompletedAppointmentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.listCompletedAppointments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/ListCompletedAppointments',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_ListCompletedAppointments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.appointment_service.GetPreAppointmentCheckoutStatusRequest,
 *   !proto.appointment_service.GetPreAppointmentCheckoutStatusResponse>}
 */
const methodDescriptor_AppointmentService_GetPreAppointmentCheckoutStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.AppointmentService/GetPreAppointmentCheckoutStatus',
  grpc.web.MethodType.UNARY,
  appointment_service_pb.GetPreAppointmentCheckoutStatusRequest,
  appointment_service_pb.GetPreAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetPreAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.appointment_service.GetPreAppointmentCheckoutStatusRequest,
 *   !proto.appointment_service.GetPreAppointmentCheckoutStatusResponse>}
 */
const methodInfo_AppointmentService_GetPreAppointmentCheckoutStatus = new grpc.web.AbstractClientBase.MethodInfo(
  appointment_service_pb.GetPreAppointmentCheckoutStatusResponse,
  /**
   * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  appointment_service_pb.GetPreAppointmentCheckoutStatusResponse.deserializeBinary
);


/**
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.appointment_service.GetPreAppointmentCheckoutStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.AppointmentServiceClient.prototype.getPreAppointmentCheckoutStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetPreAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetPreAppointmentCheckoutStatus,
      callback);
};


/**
 * @param {!proto.appointment_service.GetPreAppointmentCheckoutStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.appointment_service.GetPreAppointmentCheckoutStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.AppointmentServicePromiseClient.prototype.getPreAppointmentCheckoutStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.AppointmentService/GetPreAppointmentCheckoutStatus',
      request,
      metadata || {},
      methodDescriptor_AppointmentService_GetPreAppointmentCheckoutStatus);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DealershipServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DealershipServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.GetFleetDealershipInfoRequest,
 *   !proto.dealership_service.GetFleetDealershipInfoResponse>}
 */
const methodDescriptor_DealershipService_GetFleetDealershipInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/GetFleetDealershipInfo',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.GetFleetDealershipInfoRequest,
  dealership_service_pb.GetFleetDealershipInfoResponse,
  /**
   * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetFleetDealershipInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.GetFleetDealershipInfoRequest,
 *   !proto.dealership_service.GetFleetDealershipInfoResponse>}
 */
const methodInfo_DealershipService_GetFleetDealershipInfo = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.GetFleetDealershipInfoResponse,
  /**
   * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetFleetDealershipInfoResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.GetFleetDealershipInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.GetFleetDealershipInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.getFleetDealershipInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/GetFleetDealershipInfo',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetFleetDealershipInfo,
      callback);
};


/**
 * @param {!proto.dealership_service.GetFleetDealershipInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.GetFleetDealershipInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.getFleetDealershipInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/GetFleetDealershipInfo',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetFleetDealershipInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.GetDealerUserInfoRequest,
 *   !proto.dealership_service.GetDealerUserInfoResponse>}
 */
const methodDescriptor_DealershipService_GetDealerUserInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/GetDealerUserInfo',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.GetDealerUserInfoRequest,
  dealership_service_pb.GetDealerUserInfoResponse,
  /**
   * @param {!proto.dealership_service.GetDealerUserInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetDealerUserInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.GetDealerUserInfoRequest,
 *   !proto.dealership_service.GetDealerUserInfoResponse>}
 */
const methodInfo_DealershipService_GetDealerUserInfo = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.GetDealerUserInfoResponse,
  /**
   * @param {!proto.dealership_service.GetDealerUserInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetDealerUserInfoResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.GetDealerUserInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.GetDealerUserInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.GetDealerUserInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.getDealerUserInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/GetDealerUserInfo',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetDealerUserInfo,
      callback);
};


/**
 * @param {!proto.dealership_service.GetDealerUserInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.GetDealerUserInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.getDealerUserInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/GetDealerUserInfo',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetDealerUserInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.ViewTeamRequest,
 *   !proto.dealership_service.ViewTeamResponse>}
 */
const methodDescriptor_DealershipService_ViewTeam = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/ViewTeam',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.ViewTeamRequest,
  dealership_service_pb.ViewTeamResponse,
  /**
   * @param {!proto.dealership_service.ViewTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.ViewTeamResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.ViewTeamRequest,
 *   !proto.dealership_service.ViewTeamResponse>}
 */
const methodInfo_DealershipService_ViewTeam = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.ViewTeamResponse,
  /**
   * @param {!proto.dealership_service.ViewTeamRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.ViewTeamResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.ViewTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.ViewTeamResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.ViewTeamResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.viewTeam =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/ViewTeam',
      request,
      metadata || {},
      methodDescriptor_DealershipService_ViewTeam,
      callback);
};


/**
 * @param {!proto.dealership_service.ViewTeamRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.ViewTeamResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.viewTeam =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/ViewTeam',
      request,
      metadata || {},
      methodDescriptor_DealershipService_ViewTeam);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.ListDealershipsRequest,
 *   !proto.dealership_service.ListDealershipsResponse>}
 */
const methodDescriptor_DealershipService_ListDealerships = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/ListDealerships',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.ListDealershipsRequest,
  dealership_service_pb.ListDealershipsResponse,
  /**
   * @param {!proto.dealership_service.ListDealershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.ListDealershipsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.ListDealershipsRequest,
 *   !proto.dealership_service.ListDealershipsResponse>}
 */
const methodInfo_DealershipService_ListDealerships = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.ListDealershipsResponse,
  /**
   * @param {!proto.dealership_service.ListDealershipsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.ListDealershipsResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.ListDealershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.ListDealershipsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.ListDealershipsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.listDealerships =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/ListDealerships',
      request,
      metadata || {},
      methodDescriptor_DealershipService_ListDealerships,
      callback);
};


/**
 * @param {!proto.dealership_service.ListDealershipsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.ListDealershipsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.listDealerships =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/ListDealerships',
      request,
      metadata || {},
      methodDescriptor_DealershipService_ListDealerships);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.CreateDealershipRequest,
 *   !proto.dealership_service.CreateDealershipResponse>}
 */
const methodDescriptor_DealershipService_CreateDealership = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/CreateDealership',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.CreateDealershipRequest,
  dealership_service_pb.CreateDealershipResponse,
  /**
   * @param {!proto.dealership_service.CreateDealershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.CreateDealershipResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.CreateDealershipRequest,
 *   !proto.dealership_service.CreateDealershipResponse>}
 */
const methodInfo_DealershipService_CreateDealership = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.CreateDealershipResponse,
  /**
   * @param {!proto.dealership_service.CreateDealershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.CreateDealershipResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.CreateDealershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.CreateDealershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.CreateDealershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.createDealership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/CreateDealership',
      request,
      metadata || {},
      methodDescriptor_DealershipService_CreateDealership,
      callback);
};


/**
 * @param {!proto.dealership_service.CreateDealershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.CreateDealershipResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.createDealership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/CreateDealership',
      request,
      metadata || {},
      methodDescriptor_DealershipService_CreateDealership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.CreateDealershipUserRequest,
 *   !proto.dealership_service.CreateDealershipUserResponse>}
 */
const methodDescriptor_DealershipService_CreateDealershipUser = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/CreateDealershipUser',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.CreateDealershipUserRequest,
  dealership_service_pb.CreateDealershipUserResponse,
  /**
   * @param {!proto.dealership_service.CreateDealershipUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.CreateDealershipUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.CreateDealershipUserRequest,
 *   !proto.dealership_service.CreateDealershipUserResponse>}
 */
const methodInfo_DealershipService_CreateDealershipUser = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.CreateDealershipUserResponse,
  /**
   * @param {!proto.dealership_service.CreateDealershipUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.CreateDealershipUserResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.CreateDealershipUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.CreateDealershipUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.CreateDealershipUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.createDealershipUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/CreateDealershipUser',
      request,
      metadata || {},
      methodDescriptor_DealershipService_CreateDealershipUser,
      callback);
};


/**
 * @param {!proto.dealership_service.CreateDealershipUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.CreateDealershipUserResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.createDealershipUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/CreateDealershipUser',
      request,
      metadata || {},
      methodDescriptor_DealershipService_CreateDealershipUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.UpdateDealershipUserRequest,
 *   !proto.dealership_service.UpdateDealershipUserResponse>}
 */
const methodDescriptor_DealershipService_UpdateDealershipUser = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/UpdateDealershipUser',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.UpdateDealershipUserRequest,
  dealership_service_pb.UpdateDealershipUserResponse,
  /**
   * @param {!proto.dealership_service.UpdateDealershipUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.UpdateDealershipUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.UpdateDealershipUserRequest,
 *   !proto.dealership_service.UpdateDealershipUserResponse>}
 */
const methodInfo_DealershipService_UpdateDealershipUser = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.UpdateDealershipUserResponse,
  /**
   * @param {!proto.dealership_service.UpdateDealershipUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.UpdateDealershipUserResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.UpdateDealershipUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.UpdateDealershipUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.UpdateDealershipUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.updateDealershipUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/UpdateDealershipUser',
      request,
      metadata || {},
      methodDescriptor_DealershipService_UpdateDealershipUser,
      callback);
};


/**
 * @param {!proto.dealership_service.UpdateDealershipUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.UpdateDealershipUserResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.updateDealershipUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/UpdateDealershipUser',
      request,
      metadata || {},
      methodDescriptor_DealershipService_UpdateDealershipUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.DealershipUserSignaturesRequest,
 *   !proto.dealership_service.DealershipUserSignaturesResponse>}
 */
const methodDescriptor_DealershipService_DealershipUserSignatures = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/DealershipUserSignatures',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.DealershipUserSignaturesRequest,
  dealership_service_pb.DealershipUserSignaturesResponse,
  /**
   * @param {!proto.dealership_service.DealershipUserSignaturesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.DealershipUserSignaturesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.DealershipUserSignaturesRequest,
 *   !proto.dealership_service.DealershipUserSignaturesResponse>}
 */
const methodInfo_DealershipService_DealershipUserSignatures = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.DealershipUserSignaturesResponse,
  /**
   * @param {!proto.dealership_service.DealershipUserSignaturesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.DealershipUserSignaturesResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.DealershipUserSignaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.DealershipUserSignaturesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.DealershipUserSignaturesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.dealershipUserSignatures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/DealershipUserSignatures',
      request,
      metadata || {},
      methodDescriptor_DealershipService_DealershipUserSignatures,
      callback);
};


/**
 * @param {!proto.dealership_service.DealershipUserSignaturesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.DealershipUserSignaturesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.dealershipUserSignatures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/DealershipUserSignatures',
      request,
      metadata || {},
      methodDescriptor_DealershipService_DealershipUserSignatures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.UpdateDealershipRequest,
 *   !proto.dealership_service.UpdateDealershipResponse>}
 */
const methodDescriptor_DealershipService_UpdateDealership = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/UpdateDealership',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.UpdateDealershipRequest,
  dealership_service_pb.UpdateDealershipResponse,
  /**
   * @param {!proto.dealership_service.UpdateDealershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.UpdateDealershipResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.UpdateDealershipRequest,
 *   !proto.dealership_service.UpdateDealershipResponse>}
 */
const methodInfo_DealershipService_UpdateDealership = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.UpdateDealershipResponse,
  /**
   * @param {!proto.dealership_service.UpdateDealershipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.UpdateDealershipResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.UpdateDealershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.UpdateDealershipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.UpdateDealershipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.updateDealership =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/UpdateDealership',
      request,
      metadata || {},
      methodDescriptor_DealershipService_UpdateDealership,
      callback);
};


/**
 * @param {!proto.dealership_service.UpdateDealershipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.UpdateDealershipResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.updateDealership =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/UpdateDealership',
      request,
      metadata || {},
      methodDescriptor_DealershipService_UpdateDealership);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealership_service.GetSelfRequest,
 *   !proto.dealership_service.GetSelfResponse>}
 */
const methodDescriptor_DealershipService_GetDealershipUserSelf = new grpc.web.MethodDescriptor(
  '/gateway_service.DealershipService/GetDealershipUserSelf',
  grpc.web.MethodType.UNARY,
  dealership_service_pb.GetSelfRequest,
  dealership_service_pb.GetSelfResponse,
  /**
   * @param {!proto.dealership_service.GetSelfRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetSelfResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealership_service.GetSelfRequest,
 *   !proto.dealership_service.GetSelfResponse>}
 */
const methodInfo_DealershipService_GetDealershipUserSelf = new grpc.web.AbstractClientBase.MethodInfo(
  dealership_service_pb.GetSelfResponse,
  /**
   * @param {!proto.dealership_service.GetSelfRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealership_service_pb.GetSelfResponse.deserializeBinary
);


/**
 * @param {!proto.dealership_service.GetSelfRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealership_service.GetSelfResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealership_service.GetSelfResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealershipServiceClient.prototype.getDealershipUserSelf =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealershipService/GetDealershipUserSelf',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetDealershipUserSelf,
      callback);
};


/**
 * @param {!proto.dealership_service.GetSelfRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealership_service.GetSelfResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealershipServicePromiseClient.prototype.getDealershipUserSelf =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealershipService/GetDealershipUserSelf',
      request,
      metadata || {},
      methodDescriptor_DealershipService_GetDealershipUserSelf);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DealerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DealerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealer_service.CheckVerificationCodeLoginRequest,
 *   !proto.dealer_service.CheckVerificationCodeLoginResponse>}
 */
const methodDescriptor_DealerService_CheckVerificationCodeLogin = new grpc.web.MethodDescriptor(
  '/gateway_service.DealerService/CheckVerificationCodeLogin',
  grpc.web.MethodType.UNARY,
  dealer_service_pb.CheckVerificationCodeLoginRequest,
  dealer_service_pb.CheckVerificationCodeLoginResponse,
  /**
   * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealer_service_pb.CheckVerificationCodeLoginResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealer_service.CheckVerificationCodeLoginRequest,
 *   !proto.dealer_service.CheckVerificationCodeLoginResponse>}
 */
const methodInfo_DealerService_CheckVerificationCodeLogin = new grpc.web.AbstractClientBase.MethodInfo(
  dealer_service_pb.CheckVerificationCodeLoginResponse,
  /**
   * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealer_service_pb.CheckVerificationCodeLoginResponse.deserializeBinary
);


/**
 * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealer_service.CheckVerificationCodeLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealer_service.CheckVerificationCodeLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DealerServiceClient.prototype.checkVerificationCodeLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DealerService/CheckVerificationCodeLogin',
      request,
      metadata || {},
      methodDescriptor_DealerService_CheckVerificationCodeLogin,
      callback);
};


/**
 * @param {!proto.dealer_service.CheckVerificationCodeLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealer_service.CheckVerificationCodeLoginResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DealerServicePromiseClient.prototype.checkVerificationCodeLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DealerService/CheckVerificationCodeLogin',
      request,
      metadata || {},
      methodDescriptor_DealerService_CheckVerificationCodeLogin);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.ContractServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.ContractServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contract_service.AddTemplateRequest,
 *   !proto.contract_service.AddTemplateResponse>}
 */
const methodDescriptor_ContractService_AddTemplate = new grpc.web.MethodDescriptor(
  '/gateway_service.ContractService/AddTemplate',
  grpc.web.MethodType.UNARY,
  contract_service_pb.AddTemplateRequest,
  contract_service_pb.AddTemplateResponse,
  /**
   * @param {!proto.contract_service.AddTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  contract_service_pb.AddTemplateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.contract_service.AddTemplateRequest,
 *   !proto.contract_service.AddTemplateResponse>}
 */
const methodInfo_ContractService_AddTemplate = new grpc.web.AbstractClientBase.MethodInfo(
  contract_service_pb.AddTemplateResponse,
  /**
   * @param {!proto.contract_service.AddTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  contract_service_pb.AddTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.contract_service.AddTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.contract_service.AddTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contract_service.AddTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.ContractServiceClient.prototype.addTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.ContractService/AddTemplate',
      request,
      metadata || {},
      methodDescriptor_ContractService_AddTemplate,
      callback);
};


/**
 * @param {!proto.contract_service.AddTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contract_service.AddTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.ContractServicePromiseClient.prototype.addTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.ContractService/AddTemplate',
      request,
      metadata || {},
      methodDescriptor_ContractService_AddTemplate);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SendDeepLinkRequest,
 *   !proto.customer_service.SendDeepLinkResponse>}
 */
const methodDescriptor_CustomerService_CreateCustomerAndSendDeepLink = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/CreateCustomerAndSendDeepLink',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SendDeepLinkRequest,
  customer_service_pb.SendDeepLinkResponse,
  /**
   * @param {!proto.customer_service.SendDeepLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SendDeepLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SendDeepLinkRequest,
 *   !proto.customer_service.SendDeepLinkResponse>}
 */
const methodInfo_CustomerService_CreateCustomerAndSendDeepLink = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SendDeepLinkResponse,
  /**
   * @param {!proto.customer_service.SendDeepLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SendDeepLinkResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SendDeepLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SendDeepLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SendDeepLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.createCustomerAndSendDeepLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/CreateCustomerAndSendDeepLink',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomerAndSendDeepLink,
      callback);
};


/**
 * @param {!proto.customer_service.SendDeepLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SendDeepLinkResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.createCustomerAndSendDeepLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/CreateCustomerAndSendDeepLink',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomerAndSendDeepLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.CreateCustomerRequest,
 *   !proto.customer_service.CreateCustomerResponse>}
 */
const methodDescriptor_CustomerService_CreateCustomer = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/CreateCustomer',
  grpc.web.MethodType.UNARY,
  customer_service_pb.CreateCustomerRequest,
  customer_service_pb.CreateCustomerResponse,
  /**
   * @param {!proto.customer_service.CreateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.CreateCustomerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.CreateCustomerRequest,
 *   !proto.customer_service.CreateCustomerResponse>}
 */
const methodInfo_CustomerService_CreateCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.CreateCustomerResponse,
  /**
   * @param {!proto.customer_service.CreateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.CreateCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.CreateCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.CreateCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.createCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomer,
      callback);
};


/**
 * @param {!proto.customer_service.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.CreateCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.createCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SubscribeToActivationRequest,
 *   !proto.customer_service.ActivationState>}
 */
const methodDescriptor_CustomerService_SubscribeToActivation = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SubscribeToActivation',
  grpc.web.MethodType.SERVER_STREAMING,
  customer_service_pb.SubscribeToActivationRequest,
  customer_service_pb.ActivationState,
  /**
   * @param {!proto.customer_service.SubscribeToActivationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ActivationState.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SubscribeToActivationRequest,
 *   !proto.customer_service.ActivationState>}
 */
const methodInfo_CustomerService_SubscribeToActivation = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.ActivationState,
  /**
   * @param {!proto.customer_service.SubscribeToActivationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ActivationState.deserializeBinary
);


/**
 * @param {!proto.customer_service.SubscribeToActivationRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ActivationState>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.subscribeToActivation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.CustomerService/SubscribeToActivation',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubscribeToActivation);
};


/**
 * @param {!proto.customer_service.SubscribeToActivationRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ActivationState>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.subscribeToActivation =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.CustomerService/SubscribeToActivation',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubscribeToActivation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.QualificationRequest,
 *   !proto.customer_service.QualificationResponse>}
 */
const methodDescriptor_CustomerService_Qualify = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/Qualify',
  grpc.web.MethodType.SERVER_STREAMING,
  customer_service_pb.QualificationRequest,
  customer_service_pb.QualificationResponse,
  /**
   * @param {!proto.customer_service.QualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.QualificationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.QualificationRequest,
 *   !proto.customer_service.QualificationResponse>}
 */
const methodInfo_CustomerService_Qualify = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.QualificationResponse,
  /**
   * @param {!proto.customer_service.QualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.QualificationResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.QualificationRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.QualificationResponse>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.qualify =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.CustomerService/Qualify',
      request,
      metadata || {},
      methodDescriptor_CustomerService_Qualify);
};


/**
 * @param {!proto.customer_service.QualificationRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.QualificationResponse>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.qualify =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.CustomerService/Qualify',
      request,
      metadata || {},
      methodDescriptor_CustomerService_Qualify);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SendOTPRequest,
 *   !proto.customer_service.SendOTPResponse>}
 */
const methodDescriptor_CustomerService_SendOTP = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SendOTP',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SendOTPRequest,
  customer_service_pb.SendOTPResponse,
  /**
   * @param {!proto.customer_service.SendOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SendOTPResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SendOTPRequest,
 *   !proto.customer_service.SendOTPResponse>}
 */
const methodInfo_CustomerService_SendOTP = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SendOTPResponse,
  /**
   * @param {!proto.customer_service.SendOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SendOTPResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SendOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SendOTPResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SendOTPResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.sendOTP =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/SendOTP',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SendOTP,
      callback);
};


/**
 * @param {!proto.customer_service.SendOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SendOTPResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.sendOTP =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/SendOTP',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SendOTP);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.VerifyOTPRequest,
 *   !proto.customer_service.VerifyOTPResponse>}
 */
const methodDescriptor_CustomerService_VerifyOTP = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/VerifyOTP',
  grpc.web.MethodType.UNARY,
  customer_service_pb.VerifyOTPRequest,
  customer_service_pb.VerifyOTPResponse,
  /**
   * @param {!proto.customer_service.VerifyOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.VerifyOTPResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.VerifyOTPRequest,
 *   !proto.customer_service.VerifyOTPResponse>}
 */
const methodInfo_CustomerService_VerifyOTP = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.VerifyOTPResponse,
  /**
   * @param {!proto.customer_service.VerifyOTPRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.VerifyOTPResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.VerifyOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.VerifyOTPResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.VerifyOTPResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.verifyOTP =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/VerifyOTP',
      request,
      metadata || {},
      methodDescriptor_CustomerService_VerifyOTP,
      callback);
};


/**
 * @param {!proto.customer_service.VerifyOTPRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.VerifyOTPResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.verifyOTP =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/VerifyOTP',
      request,
      metadata || {},
      methodDescriptor_CustomerService_VerifyOTP);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SubmitLicenseInfoRequest,
 *   !proto.customer_service.SubmitLicenseInfoResponse>}
 */
const methodDescriptor_CustomerService_SubmitLicenseInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SubmitLicenseInfo',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SubmitLicenseInfoRequest,
  customer_service_pb.SubmitLicenseInfoResponse,
  /**
   * @param {!proto.customer_service.SubmitLicenseInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitLicenseInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SubmitLicenseInfoRequest,
 *   !proto.customer_service.SubmitLicenseInfoResponse>}
 */
const methodInfo_CustomerService_SubmitLicenseInfo = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SubmitLicenseInfoResponse,
  /**
   * @param {!proto.customer_service.SubmitLicenseInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitLicenseInfoResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SubmitLicenseInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SubmitLicenseInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SubmitLicenseInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.submitLicenseInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitLicenseInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitLicenseInfo,
      callback);
};


/**
 * @param {!proto.customer_service.SubmitLicenseInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SubmitLicenseInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.submitLicenseInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitLicenseInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitLicenseInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SubmitSelfiePhotoRequest,
 *   !proto.customer_service.SubmitSelfiePhotoResponse>}
 */
const methodDescriptor_CustomerService_SubmitSelfiePhoto = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SubmitSelfiePhoto',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SubmitSelfiePhotoRequest,
  customer_service_pb.SubmitSelfiePhotoResponse,
  /**
   * @param {!proto.customer_service.SubmitSelfiePhotoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitSelfiePhotoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SubmitSelfiePhotoRequest,
 *   !proto.customer_service.SubmitSelfiePhotoResponse>}
 */
const methodInfo_CustomerService_SubmitSelfiePhoto = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SubmitSelfiePhotoResponse,
  /**
   * @param {!proto.customer_service.SubmitSelfiePhotoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitSelfiePhotoResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SubmitSelfiePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SubmitSelfiePhotoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SubmitSelfiePhotoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.submitSelfiePhoto =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitSelfiePhoto',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitSelfiePhoto,
      callback);
};


/**
 * @param {!proto.customer_service.SubmitSelfiePhotoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SubmitSelfiePhotoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.submitSelfiePhoto =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitSelfiePhoto',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitSelfiePhoto);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SubmitLicensePhotosRequest,
 *   !proto.customer_service.SubmitLicensePhotosResponse>}
 */
const methodDescriptor_CustomerService_SubmitLicensePhotos = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SubmitLicensePhotos',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SubmitLicensePhotosRequest,
  customer_service_pb.SubmitLicensePhotosResponse,
  /**
   * @param {!proto.customer_service.SubmitLicensePhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitLicensePhotosResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SubmitLicensePhotosRequest,
 *   !proto.customer_service.SubmitLicensePhotosResponse>}
 */
const methodInfo_CustomerService_SubmitLicensePhotos = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SubmitLicensePhotosResponse,
  /**
   * @param {!proto.customer_service.SubmitLicensePhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SubmitLicensePhotosResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SubmitLicensePhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SubmitLicensePhotosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SubmitLicensePhotosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.submitLicensePhotos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitLicensePhotos',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitLicensePhotos,
      callback);
};


/**
 * @param {!proto.customer_service.SubmitLicensePhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SubmitLicensePhotosResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.submitLicensePhotos =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/SubmitLicensePhotos',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SubmitLicensePhotos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.SetActivityRequest,
 *   !proto.customer_service.SetActivityResponse>}
 */
const methodDescriptor_CustomerService_SetActivity = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/SetActivity',
  grpc.web.MethodType.UNARY,
  customer_service_pb.SetActivityRequest,
  customer_service_pb.SetActivityResponse,
  /**
   * @param {!proto.customer_service.SetActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SetActivityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.SetActivityRequest,
 *   !proto.customer_service.SetActivityResponse>}
 */
const methodInfo_CustomerService_SetActivity = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.SetActivityResponse,
  /**
   * @param {!proto.customer_service.SetActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.SetActivityResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.SetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.SetActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.SetActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.setActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/SetActivity',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SetActivity,
      callback);
};


/**
 * @param {!proto.customer_service.SetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.SetActivityResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.setActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/SetActivity',
      request,
      metadata || {},
      methodDescriptor_CustomerService_SetActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetSelfRequest,
 *   !proto.customer_service.GetSelfResponse>}
 */
const methodDescriptor_CustomerService_GetSelf = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetSelf',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetSelfRequest,
  customer_service_pb.GetSelfResponse,
  /**
   * @param {!proto.customer_service.GetSelfRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetSelfResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetSelfRequest,
 *   !proto.customer_service.GetSelfResponse>}
 */
const methodInfo_CustomerService_GetSelf = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetSelfResponse,
  /**
   * @param {!proto.customer_service.GetSelfRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetSelfResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetSelfRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetSelfResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetSelfResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getSelf =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetSelf',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetSelf,
      callback);
};


/**
 * @param {!proto.customer_service.GetSelfRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetSelfResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getSelf =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetSelf',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetSelf);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetStateRequest,
 *   !proto.customer_service.GetStateResponse>}
 */
const methodDescriptor_CustomerService_GetState = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetState',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetStateRequest,
  customer_service_pb.GetStateResponse,
  /**
   * @param {!proto.customer_service.GetStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetStateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetStateRequest,
 *   !proto.customer_service.GetStateResponse>}
 */
const methodInfo_CustomerService_GetState = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetStateResponse,
  /**
   * @param {!proto.customer_service.GetStateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetStateResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetStateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetStateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getState =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetState',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetState,
      callback);
};


/**
 * @param {!proto.customer_service.GetStateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetStateResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getState =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetState',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetState);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetAddressesRequest,
 *   !proto.customer_service.GetAddressesResponse>}
 */
const methodDescriptor_CustomerService_GetAddresses = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetAddresses',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetAddressesRequest,
  customer_service_pb.GetAddressesResponse,
  /**
   * @param {!proto.customer_service.GetAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetAddressesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetAddressesRequest,
 *   !proto.customer_service.GetAddressesResponse>}
 */
const methodInfo_CustomerService_GetAddresses = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetAddressesResponse,
  /**
   * @param {!proto.customer_service.GetAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetAddresses,
      callback);
};


/**
 * @param {!proto.customer_service.GetAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetAddressesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetOwnAddressesRequest,
 *   !proto.customer_service.GetAddressesResponse>}
 */
const methodDescriptor_CustomerService_GetOwnAddresses = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetOwnAddresses',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetOwnAddressesRequest,
  customer_service_pb.GetAddressesResponse,
  /**
   * @param {!proto.customer_service.GetOwnAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetAddressesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetOwnAddressesRequest,
 *   !proto.customer_service.GetAddressesResponse>}
 */
const methodInfo_CustomerService_GetOwnAddresses = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetAddressesResponse,
  /**
   * @param {!proto.customer_service.GetOwnAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetOwnAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getOwnAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetOwnAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetOwnAddresses,
      callback);
};


/**
 * @param {!proto.customer_service.GetOwnAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetAddressesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getOwnAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetOwnAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetOwnAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetInsurancePoliciesRequest,
 *   !proto.customer_service.GetInsurancePoliciesResponse>}
 */
const methodDescriptor_CustomerService_GetInsurancePolicies = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetInsurancePolicies',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetInsurancePoliciesRequest,
  customer_service_pb.GetInsurancePoliciesResponse,
  /**
   * @param {!proto.customer_service.GetInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetInsurancePoliciesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetInsurancePoliciesRequest,
 *   !proto.customer_service.GetInsurancePoliciesResponse>}
 */
const methodInfo_CustomerService_GetInsurancePolicies = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetInsurancePoliciesResponse,
  /**
   * @param {!proto.customer_service.GetInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetInsurancePoliciesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetInsurancePoliciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetInsurancePoliciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getInsurancePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetInsurancePolicies,
      callback);
};


/**
 * @param {!proto.customer_service.GetInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetInsurancePoliciesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getInsurancePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetInsurancePolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetImagesRequest,
 *   !proto.customer_service.GetImagesResponse>}
 */
const methodDescriptor_CustomerService_GetImages = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetImages',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetImagesRequest,
  customer_service_pb.GetImagesResponse,
  /**
   * @param {!proto.customer_service.GetImagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetImagesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetImagesRequest,
 *   !proto.customer_service.GetImagesResponse>}
 */
const methodInfo_CustomerService_GetImages = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetImagesResponse,
  /**
   * @param {!proto.customer_service.GetImagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetImagesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetImagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetImagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getImages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetImages',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetImages,
      callback);
};


/**
 * @param {!proto.customer_service.GetImagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetImagesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getImages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetImages',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetImages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpdateEmailRequest,
 *   !proto.customer_service.UpdateEmailResponse>}
 */
const methodDescriptor_CustomerService_UpdateEmail = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpdateEmail',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpdateEmailRequest,
  customer_service_pb.UpdateEmailResponse,
  /**
   * @param {!proto.customer_service.UpdateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateEmailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpdateEmailRequest,
 *   !proto.customer_service.UpdateEmailResponse>}
 */
const methodInfo_CustomerService_UpdateEmail = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpdateEmailResponse,
  /**
   * @param {!proto.customer_service.UpdateEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateEmailResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpdateEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpdateEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.updateEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateEmail,
      callback);
};


/**
 * @param {!proto.customer_service.UpdateEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpdateEmailResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.updateEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateEmail',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpsertInsurancePoliciesRequest,
 *   !proto.customer_service.UpsertInsurancePoliciesResponse>}
 */
const methodDescriptor_CustomerService_UpsertInsurancePolicies = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpsertInsurancePolicies',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpsertInsurancePoliciesRequest,
  customer_service_pb.UpsertInsurancePoliciesResponse,
  /**
   * @param {!proto.customer_service.UpsertInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertInsurancePoliciesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpsertInsurancePoliciesRequest,
 *   !proto.customer_service.UpsertInsurancePoliciesResponse>}
 */
const methodInfo_CustomerService_UpsertInsurancePolicies = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpsertInsurancePoliciesResponse,
  /**
   * @param {!proto.customer_service.UpsertInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertInsurancePoliciesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpsertInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpsertInsurancePoliciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpsertInsurancePoliciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.upsertInsurancePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertInsurancePolicies,
      callback);
};


/**
 * @param {!proto.customer_service.UpsertInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpsertInsurancePoliciesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.upsertInsurancePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertInsurancePolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpsertAddressesRequest,
 *   !proto.customer_service.UpsertAddressesResponse>}
 */
const methodDescriptor_CustomerService_UpsertAddresses = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpsertAddresses',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpsertAddressesRequest,
  customer_service_pb.UpsertAddressesResponse,
  /**
   * @param {!proto.customer_service.UpsertAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertAddressesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpsertAddressesRequest,
 *   !proto.customer_service.UpsertAddressesResponse>}
 */
const methodInfo_CustomerService_UpsertAddresses = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpsertAddressesResponse,
  /**
   * @param {!proto.customer_service.UpsertAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpsertAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpsertAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpsertAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.upsertAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertAddresses,
      callback);
};


/**
 * @param {!proto.customer_service.UpsertAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpsertAddressesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.upsertAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpsertOwnAddressesRequest,
 *   !proto.customer_service.UpsertAddressesResponse>}
 */
const methodDescriptor_CustomerService_UpsertOwnAddresses = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpsertOwnAddresses',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpsertOwnAddressesRequest,
  customer_service_pb.UpsertAddressesResponse,
  /**
   * @param {!proto.customer_service.UpsertOwnAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertAddressesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpsertOwnAddressesRequest,
 *   !proto.customer_service.UpsertAddressesResponse>}
 */
const methodInfo_CustomerService_UpsertOwnAddresses = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpsertAddressesResponse,
  /**
   * @param {!proto.customer_service.UpsertOwnAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpsertAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpsertOwnAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpsertAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpsertAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.upsertOwnAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertOwnAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertOwnAddresses,
      callback);
};


/**
 * @param {!proto.customer_service.UpsertOwnAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpsertAddressesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.upsertOwnAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpsertOwnAddresses',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpsertOwnAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.GetCustomerByIDRequest,
 *   !proto.customer_service.GetCustomerByIDResponse>}
 */
const methodDescriptor_CustomerService_GetCustomerByID = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/GetCustomerByID',
  grpc.web.MethodType.UNARY,
  customer_service_pb.GetCustomerByIDRequest,
  customer_service_pb.GetCustomerByIDResponse,
  /**
   * @param {!proto.customer_service.GetCustomerByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetCustomerByIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.GetCustomerByIDRequest,
 *   !proto.customer_service.GetCustomerByIDResponse>}
 */
const methodInfo_CustomerService_GetCustomerByID = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.GetCustomerByIDResponse,
  /**
   * @param {!proto.customer_service.GetCustomerByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.GetCustomerByIDResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.GetCustomerByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.GetCustomerByIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.GetCustomerByIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.getCustomerByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/GetCustomerByID',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByID,
      callback);
};


/**
 * @param {!proto.customer_service.GetCustomerByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.GetCustomerByIDResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.getCustomerByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/GetCustomerByID',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.ValidateClientRequest,
 *   !proto.customer_service.ValidateClientResponse>}
 */
const methodDescriptor_CustomerService_ValidateClient = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/ValidateClient',
  grpc.web.MethodType.UNARY,
  customer_service_pb.ValidateClientRequest,
  customer_service_pb.ValidateClientResponse,
  /**
   * @param {!proto.customer_service.ValidateClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ValidateClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.ValidateClientRequest,
 *   !proto.customer_service.ValidateClientResponse>}
 */
const methodInfo_CustomerService_ValidateClient = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.ValidateClientResponse,
  /**
   * @param {!proto.customer_service.ValidateClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ValidateClientResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.ValidateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.ValidateClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ValidateClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.validateClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/ValidateClient',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ValidateClient,
      callback);
};


/**
 * @param {!proto.customer_service.ValidateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.ValidateClientResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.validateClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/ValidateClient',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ValidateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.ExistsByPhoneRequest,
 *   !proto.customer_service.ExistsByPhoneResponse>}
 */
const methodDescriptor_CustomerService_ExistsByPhone = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/ExistsByPhone',
  grpc.web.MethodType.UNARY,
  customer_service_pb.ExistsByPhoneRequest,
  customer_service_pb.ExistsByPhoneResponse,
  /**
   * @param {!proto.customer_service.ExistsByPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ExistsByPhoneResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.ExistsByPhoneRequest,
 *   !proto.customer_service.ExistsByPhoneResponse>}
 */
const methodInfo_CustomerService_ExistsByPhone = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.ExistsByPhoneResponse,
  /**
   * @param {!proto.customer_service.ExistsByPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ExistsByPhoneResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.ExistsByPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.ExistsByPhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ExistsByPhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.existsByPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/ExistsByPhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ExistsByPhone,
      callback);
};


/**
 * @param {!proto.customer_service.ExistsByPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.ExistsByPhoneResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.existsByPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/ExistsByPhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ExistsByPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.RecalculateQualificationRequest,
 *   !proto.customer_service.RecalculateQualificationResponse>}
 */
const methodDescriptor_CustomerService_RecalculateQualification = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/RecalculateQualification',
  grpc.web.MethodType.UNARY,
  customer_service_pb.RecalculateQualificationRequest,
  customer_service_pb.RecalculateQualificationResponse,
  /**
   * @param {!proto.customer_service.RecalculateQualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.RecalculateQualificationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.RecalculateQualificationRequest,
 *   !proto.customer_service.RecalculateQualificationResponse>}
 */
const methodInfo_CustomerService_RecalculateQualification = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.RecalculateQualificationResponse,
  /**
   * @param {!proto.customer_service.RecalculateQualificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.RecalculateQualificationResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.RecalculateQualificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.RecalculateQualificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.RecalculateQualificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.recalculateQualification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/RecalculateQualification',
      request,
      metadata || {},
      methodDescriptor_CustomerService_RecalculateQualification,
      callback);
};


/**
 * @param {!proto.customer_service.RecalculateQualificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.RecalculateQualificationResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.recalculateQualification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/RecalculateQualification',
      request,
      metadata || {},
      methodDescriptor_CustomerService_RecalculateQualification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.ResetLoginAttemptsRequest,
 *   !proto.customer_service.ResetLoginAttemptsResponse>}
 */
const methodDescriptor_CustomerService_ResetLoginAttempts = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/ResetLoginAttempts',
  grpc.web.MethodType.UNARY,
  customer_service_pb.ResetLoginAttemptsRequest,
  customer_service_pb.ResetLoginAttemptsResponse,
  /**
   * @param {!proto.customer_service.ResetLoginAttemptsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ResetLoginAttemptsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.ResetLoginAttemptsRequest,
 *   !proto.customer_service.ResetLoginAttemptsResponse>}
 */
const methodInfo_CustomerService_ResetLoginAttempts = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.ResetLoginAttemptsResponse,
  /**
   * @param {!proto.customer_service.ResetLoginAttemptsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ResetLoginAttemptsResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.ResetLoginAttemptsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.ResetLoginAttemptsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ResetLoginAttemptsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.resetLoginAttempts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/ResetLoginAttempts',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ResetLoginAttempts,
      callback);
};


/**
 * @param {!proto.customer_service.ResetLoginAttemptsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.ResetLoginAttemptsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.resetLoginAttempts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/ResetLoginAttempts',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ResetLoginAttempts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpdateCustomerWithFakePhoneRequest,
 *   !proto.customer_service.UpdateCustomerWithFakePhoneResponse>}
 */
const methodDescriptor_CustomerService_UpdateCustomerWithFakePhone = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpdateCustomerWithFakePhone',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpdateCustomerWithFakePhoneRequest,
  customer_service_pb.UpdateCustomerWithFakePhoneResponse,
  /**
   * @param {!proto.customer_service.UpdateCustomerWithFakePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateCustomerWithFakePhoneResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpdateCustomerWithFakePhoneRequest,
 *   !proto.customer_service.UpdateCustomerWithFakePhoneResponse>}
 */
const methodInfo_CustomerService_UpdateCustomerWithFakePhone = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpdateCustomerWithFakePhoneResponse,
  /**
   * @param {!proto.customer_service.UpdateCustomerWithFakePhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateCustomerWithFakePhoneResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpdateCustomerWithFakePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpdateCustomerWithFakePhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpdateCustomerWithFakePhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.updateCustomerWithFakePhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateCustomerWithFakePhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomerWithFakePhone,
      callback);
};


/**
 * @param {!proto.customer_service.UpdateCustomerWithFakePhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpdateCustomerWithFakePhoneResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.updateCustomerWithFakePhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateCustomerWithFakePhone',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomerWithFakePhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.UpdateCustomerRequest,
 *   !proto.customer_service.UpdateCustomerResponse>}
 */
const methodDescriptor_CustomerService_UpdateCustomer = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/UpdateCustomer',
  grpc.web.MethodType.UNARY,
  customer_service_pb.UpdateCustomerRequest,
  customer_service_pb.UpdateCustomerResponse,
  /**
   * @param {!proto.customer_service.UpdateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateCustomerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.UpdateCustomerRequest,
 *   !proto.customer_service.UpdateCustomerResponse>}
 */
const methodInfo_CustomerService_UpdateCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.UpdateCustomerResponse,
  /**
   * @param {!proto.customer_service.UpdateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.UpdateCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.UpdateCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.UpdateCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.updateCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomer,
      callback);
};


/**
 * @param {!proto.customer_service.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.UpdateCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.updateCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_service.ListCustomersRequest,
 *   !proto.customer_service.ListCustomersResponse>}
 */
const methodDescriptor_CustomerService_ListCustomers = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerService/ListCustomers',
  grpc.web.MethodType.UNARY,
  customer_service_pb.ListCustomersRequest,
  customer_service_pb.ListCustomersResponse,
  /**
   * @param {!proto.customer_service.ListCustomersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ListCustomersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_service.ListCustomersRequest,
 *   !proto.customer_service.ListCustomersResponse>}
 */
const methodInfo_CustomerService_ListCustomers = new grpc.web.AbstractClientBase.MethodInfo(
  customer_service_pb.ListCustomersResponse,
  /**
   * @param {!proto.customer_service.ListCustomersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_service_pb.ListCustomersResponse.deserializeBinary
);


/**
 * @param {!proto.customer_service.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_service.ListCustomersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_service.ListCustomersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerServiceClient.prototype.listCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerService/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ListCustomers,
      callback);
};


/**
 * @param {!proto.customer_service.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_service.ListCustomersResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerServicePromiseClient.prototype.listCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerService/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ListCustomers);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerSupportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerSupportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.GetPermissionsRequest,
 *   !proto.customer_support_service.Permissions>}
 */
const methodDescriptor_CustomerSupportService_GetPermissions = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetPermissions',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.GetPermissionsRequest,
  customer_support_service_pb.Permissions,
  /**
   * @param {!proto.customer_support_service.GetPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.Permissions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.GetPermissionsRequest,
 *   !proto.customer_support_service.Permissions>}
 */
const methodInfo_CustomerSupportService_GetPermissions = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.Permissions,
  /**
   * @param {!proto.customer_support_service.GetPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.Permissions.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.Permissions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.Permissions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetPermissions,
      callback);
};


/**
 * @param {!proto.customer_support_service.GetPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.Permissions>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetPermissions',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.domain.Customer>}
 */
const methodDescriptor_CustomerSupportService_GetCustomer = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetCustomer',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.CustomerIdentity,
  domain_pb.Customer,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  domain_pb.Customer.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.domain.Customer>}
 */
const methodInfo_CustomerSupportService_GetCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  domain_pb.Customer,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  domain_pb.Customer.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.domain.Customer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.domain.Customer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomer,
      callback);
};


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.domain.Customer>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerInfo>}
 */
const methodDescriptor_CustomerSupportService_GetCustomerInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetCustomerInfo',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.CustomerIdentity,
  customer_support_service_pb.CustomerInfo,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerInfo>}
 */
const methodInfo_CustomerSupportService_GetCustomerInfo = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.CustomerInfo,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInfo.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.CustomerInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.CustomerInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getCustomerInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerInfo,
      callback);
};


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.CustomerInfo>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getCustomerInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.UpdateCustomerInfoRequest,
 *   !proto.customer_support_service.UpdateCustomerInfoResponse>}
 */
const methodDescriptor_CustomerSupportService_UpdateCustomerInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/UpdateCustomerInfo',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.UpdateCustomerInfoRequest,
  customer_support_service_pb.UpdateCustomerInfoResponse,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.UpdateCustomerInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.UpdateCustomerInfoRequest,
 *   !proto.customer_support_service.UpdateCustomerInfoResponse>}
 */
const methodInfo_CustomerSupportService_UpdateCustomerInfo = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.UpdateCustomerInfoResponse,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.UpdateCustomerInfoResponse.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.UpdateCustomerInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.UpdateCustomerInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.updateCustomerInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerInfo,
      callback);
};


/**
 * @param {!proto.customer_support_service.UpdateCustomerInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.UpdateCustomerInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.updateCustomerInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.UpdateCustomerGarageAddressRequest,
 *   !proto.customer_support_service.UpdateCustomerGarageAddressResponse>}
 */
const methodDescriptor_CustomerSupportService_UpdateCustomerGarageAddress = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/UpdateCustomerGarageAddress',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.UpdateCustomerGarageAddressRequest,
  customer_support_service_pb.UpdateCustomerGarageAddressResponse,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.UpdateCustomerGarageAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.UpdateCustomerGarageAddressRequest,
 *   !proto.customer_support_service.UpdateCustomerGarageAddressResponse>}
 */
const methodInfo_CustomerSupportService_UpdateCustomerGarageAddress = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.UpdateCustomerGarageAddressResponse,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.UpdateCustomerGarageAddressResponse.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.UpdateCustomerGarageAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.UpdateCustomerGarageAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.updateCustomerGarageAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerGarageAddress',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerGarageAddress,
      callback);
};


/**
 * @param {!proto.customer_support_service.UpdateCustomerGarageAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.UpdateCustomerGarageAddressResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.updateCustomerGarageAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerGarageAddress',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerGarageAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerInsuranceInfo>}
 */
const methodDescriptor_CustomerSupportService_GetCustomerInsuranceInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetCustomerInsuranceInfo',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.CustomerIdentity,
  customer_support_service_pb.CustomerInsuranceInfo,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInsuranceInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerInsuranceInfo>}
 */
const methodInfo_CustomerSupportService_GetCustomerInsuranceInfo = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.CustomerInsuranceInfo,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInsuranceInfo.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.CustomerInsuranceInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.CustomerInsuranceInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getCustomerInsuranceInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerInsuranceInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerInsuranceInfo,
      callback);
};


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.CustomerInsuranceInfo>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getCustomerInsuranceInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerInsuranceInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerInsuranceInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.UpdateCustomerInsuranceInfoRequest,
 *   !proto.customer_support_service.CustomerInsuranceInfo>}
 */
const methodDescriptor_CustomerSupportService_UpdateCustomerInsuranceInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/UpdateCustomerInsuranceInfo',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.UpdateCustomerInsuranceInfoRequest,
  customer_support_service_pb.CustomerInsuranceInfo,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInsuranceInfo.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.UpdateCustomerInsuranceInfoRequest,
 *   !proto.customer_support_service.CustomerInsuranceInfo>}
 */
const methodInfo_CustomerSupportService_UpdateCustomerInsuranceInfo = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.CustomerInsuranceInfo,
  /**
   * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerInsuranceInfo.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.CustomerInsuranceInfo)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.CustomerInsuranceInfo>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.updateCustomerInsuranceInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerInsuranceInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerInsuranceInfo,
      callback);
};


/**
 * @param {!proto.customer_support_service.UpdateCustomerInsuranceInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.CustomerInsuranceInfo>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.updateCustomerInsuranceInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/UpdateCustomerInsuranceInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_UpdateCustomerInsuranceInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerSubscriptions>}
 */
const methodDescriptor_CustomerSupportService_GetCustomerSubscriptions = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetCustomerSubscriptions',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.CustomerIdentity,
  customer_support_service_pb.CustomerSubscriptions,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerSubscriptions.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.CustomerIdentity,
 *   !proto.customer_support_service.CustomerSubscriptions>}
 */
const methodInfo_CustomerSupportService_GetCustomerSubscriptions = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.CustomerSubscriptions,
  /**
   * @param {!proto.customer_support_service.CustomerIdentity} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.CustomerSubscriptions.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.CustomerSubscriptions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.CustomerSubscriptions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getCustomerSubscriptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerSubscriptions',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerSubscriptions,
      callback);
};


/**
 * @param {!proto.customer_support_service.CustomerIdentity} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.CustomerSubscriptions>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getCustomerSubscriptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetCustomerSubscriptions',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetCustomerSubscriptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer_support_service.WipeTwilioFactorsRequest,
 *   !proto.customer_support_service.WipeTwilioFactorsResponse>}
 */
const methodDescriptor_CustomerSupportService_WipeTwilioFactors = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/WipeTwilioFactors',
  grpc.web.MethodType.UNARY,
  customer_support_service_pb.WipeTwilioFactorsRequest,
  customer_support_service_pb.WipeTwilioFactorsResponse,
  /**
   * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.WipeTwilioFactorsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.customer_support_service.WipeTwilioFactorsRequest,
 *   !proto.customer_support_service.WipeTwilioFactorsResponse>}
 */
const methodInfo_CustomerSupportService_WipeTwilioFactors = new grpc.web.AbstractClientBase.MethodInfo(
  customer_support_service_pb.WipeTwilioFactorsResponse,
  /**
   * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  customer_support_service_pb.WipeTwilioFactorsResponse.deserializeBinary
);


/**
 * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.customer_support_service.WipeTwilioFactorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer_support_service.WipeTwilioFactorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.wipeTwilioFactors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/WipeTwilioFactors',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_WipeTwilioFactors,
      callback);
};


/**
 * @param {!proto.customer_support_service.WipeTwilioFactorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer_support_service.WipeTwilioFactorsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.wipeTwilioFactors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/WipeTwilioFactors',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_WipeTwilioFactors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dealer_service.GetDealershipInfoRequest,
 *   !proto.dealer_service.GetDealershipInfoResponse>}
 */
const methodDescriptor_CustomerSupportService_GetDealershipInfo = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSupportService/GetDealershipInfo',
  grpc.web.MethodType.UNARY,
  dealer_service_pb.GetDealershipInfoRequest,
  dealer_service_pb.GetDealershipInfoResponse,
  /**
   * @param {!proto.dealer_service.GetDealershipInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealer_service_pb.GetDealershipInfoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.dealer_service.GetDealershipInfoRequest,
 *   !proto.dealer_service.GetDealershipInfoResponse>}
 */
const methodInfo_CustomerSupportService_GetDealershipInfo = new grpc.web.AbstractClientBase.MethodInfo(
  dealer_service_pb.GetDealershipInfoResponse,
  /**
   * @param {!proto.dealer_service.GetDealershipInfoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  dealer_service_pb.GetDealershipInfoResponse.deserializeBinary
);


/**
 * @param {!proto.dealer_service.GetDealershipInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.dealer_service.GetDealershipInfoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dealer_service.GetDealershipInfoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSupportServiceClient.prototype.getDealershipInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetDealershipInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetDealershipInfo,
      callback);
};


/**
 * @param {!proto.dealer_service.GetDealershipInfoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dealer_service.GetDealershipInfoResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSupportServicePromiseClient.prototype.getDealershipInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSupportService/GetDealershipInfo',
      request,
      metadata || {},
      methodDescriptor_CustomerSupportService_GetDealershipInfo);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.PaymentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.PaymentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.PayByTokenRequest,
 *   !proto.payment_service.PayByTokenResponse>}
 */
const methodDescriptor_PaymentService_PayByToken = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/PayByToken',
  grpc.web.MethodType.UNARY,
  payment_service_pb.PayByTokenRequest,
  payment_service_pb.PayByTokenResponse,
  /**
   * @param {!proto.payment_service.PayByTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.PayByTokenResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.PayByTokenRequest,
 *   !proto.payment_service.PayByTokenResponse>}
 */
const methodInfo_PaymentService_PayByToken = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.PayByTokenResponse,
  /**
   * @param {!proto.payment_service.PayByTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.PayByTokenResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.PayByTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.PayByTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.PayByTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.payByToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/PayByToken',
      request,
      metadata || {},
      methodDescriptor_PaymentService_PayByToken,
      callback);
};


/**
 * @param {!proto.payment_service.PayByTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.PayByTokenResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.payByToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/PayByToken',
      request,
      metadata || {},
      methodDescriptor_PaymentService_PayByToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.ListOwnPaymentsRequest,
 *   !proto.payment_service.ListPaymentsResponse>}
 */
const methodDescriptor_PaymentService_ListOwnPayments = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/ListOwnPayments',
  grpc.web.MethodType.UNARY,
  payment_service_pb.ListOwnPaymentsRequest,
  payment_service_pb.ListPaymentsResponse,
  /**
   * @param {!proto.payment_service.ListOwnPaymentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ListPaymentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.ListOwnPaymentsRequest,
 *   !proto.payment_service.ListPaymentsResponse>}
 */
const methodInfo_PaymentService_ListOwnPayments = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.ListPaymentsResponse,
  /**
   * @param {!proto.payment_service.ListOwnPaymentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ListPaymentsResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.ListOwnPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.ListPaymentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.ListPaymentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.listOwnPayments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/ListOwnPayments',
      request,
      metadata || {},
      methodDescriptor_PaymentService_ListOwnPayments,
      callback);
};


/**
 * @param {!proto.payment_service.ListOwnPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.ListPaymentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.listOwnPayments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/ListOwnPayments',
      request,
      metadata || {},
      methodDescriptor_PaymentService_ListOwnPayments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.ListPaymentsRequest,
 *   !proto.payment_service.ListPaymentsResponse>}
 */
const methodDescriptor_PaymentService_ListPayments = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/ListPayments',
  grpc.web.MethodType.UNARY,
  payment_service_pb.ListPaymentsRequest,
  payment_service_pb.ListPaymentsResponse,
  /**
   * @param {!proto.payment_service.ListPaymentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ListPaymentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.ListPaymentsRequest,
 *   !proto.payment_service.ListPaymentsResponse>}
 */
const methodInfo_PaymentService_ListPayments = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.ListPaymentsResponse,
  /**
   * @param {!proto.payment_service.ListPaymentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ListPaymentsResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.ListPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.ListPaymentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.ListPaymentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.listPayments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/ListPayments',
      request,
      metadata || {},
      methodDescriptor_PaymentService_ListPayments,
      callback);
};


/**
 * @param {!proto.payment_service.ListPaymentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.ListPaymentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.listPayments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/ListPayments',
      request,
      metadata || {},
      methodDescriptor_PaymentService_ListPayments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.ScheduledPickupRequest,
 *   !proto.payment_service.ScheduledPickupResponse>}
 */
const methodDescriptor_PaymentService_SubmitScheduledPickupDate = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/SubmitScheduledPickupDate',
  grpc.web.MethodType.UNARY,
  payment_service_pb.ScheduledPickupRequest,
  payment_service_pb.ScheduledPickupResponse,
  /**
   * @param {!proto.payment_service.ScheduledPickupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ScheduledPickupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.ScheduledPickupRequest,
 *   !proto.payment_service.ScheduledPickupResponse>}
 */
const methodInfo_PaymentService_SubmitScheduledPickupDate = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.ScheduledPickupResponse,
  /**
   * @param {!proto.payment_service.ScheduledPickupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.ScheduledPickupResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.ScheduledPickupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.ScheduledPickupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.ScheduledPickupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.submitScheduledPickupDate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/SubmitScheduledPickupDate',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SubmitScheduledPickupDate,
      callback);
};


/**
 * @param {!proto.payment_service.ScheduledPickupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.ScheduledPickupResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.submitScheduledPickupDate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/SubmitScheduledPickupDate',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SubmitScheduledPickupDate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.PaymentsVendorTokenRequest,
 *   !proto.payment_service.PaymentsVendorToken>}
 */
const methodDescriptor_PaymentService_GetPaymentsVendorToken = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/GetPaymentsVendorToken',
  grpc.web.MethodType.UNARY,
  payment_service_pb.PaymentsVendorTokenRequest,
  payment_service_pb.PaymentsVendorToken,
  /**
   * @param {!proto.payment_service.PaymentsVendorTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.PaymentsVendorToken.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.PaymentsVendorTokenRequest,
 *   !proto.payment_service.PaymentsVendorToken>}
 */
const methodInfo_PaymentService_GetPaymentsVendorToken = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.PaymentsVendorToken,
  /**
   * @param {!proto.payment_service.PaymentsVendorTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.PaymentsVendorToken.deserializeBinary
);


/**
 * @param {!proto.payment_service.PaymentsVendorTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.PaymentsVendorToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.PaymentsVendorToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.getPaymentsVendorToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/GetPaymentsVendorToken',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetPaymentsVendorToken,
      callback);
};


/**
 * @param {!proto.payment_service.PaymentsVendorTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.PaymentsVendorToken>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.getPaymentsVendorToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/GetPaymentsVendorToken',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetPaymentsVendorToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.GetOwnPrimaryPaymentMethodRequest,
 *   !proto.payment_service.GetPrimaryPaymentMethodResponse>}
 */
const methodDescriptor_PaymentService_GetOwnPrimaryPaymentMethod = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/GetOwnPrimaryPaymentMethod',
  grpc.web.MethodType.UNARY,
  payment_service_pb.GetOwnPrimaryPaymentMethodRequest,
  payment_service_pb.GetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.GetOwnPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.GetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.GetOwnPrimaryPaymentMethodRequest,
 *   !proto.payment_service.GetPrimaryPaymentMethodResponse>}
 */
const methodInfo_PaymentService_GetOwnPrimaryPaymentMethod = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.GetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.GetOwnPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.GetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.GetOwnPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.GetPrimaryPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.GetPrimaryPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.getOwnPrimaryPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/GetOwnPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetOwnPrimaryPaymentMethod,
      callback);
};


/**
 * @param {!proto.payment_service.GetOwnPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.GetPrimaryPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.getOwnPrimaryPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/GetOwnPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetOwnPrimaryPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.GetPrimaryPaymentMethodRequest,
 *   !proto.payment_service.GetPrimaryPaymentMethodResponse>}
 */
const methodDescriptor_PaymentService_GetPrimaryPaymentMethod = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/GetPrimaryPaymentMethod',
  grpc.web.MethodType.UNARY,
  payment_service_pb.GetPrimaryPaymentMethodRequest,
  payment_service_pb.GetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.GetPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.GetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.GetPrimaryPaymentMethodRequest,
 *   !proto.payment_service.GetPrimaryPaymentMethodResponse>}
 */
const methodInfo_PaymentService_GetPrimaryPaymentMethod = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.GetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.GetPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.GetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.GetPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.GetPrimaryPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.GetPrimaryPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.getPrimaryPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/GetPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetPrimaryPaymentMethod,
      callback);
};


/**
 * @param {!proto.payment_service.GetPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.GetPrimaryPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.getPrimaryPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/GetPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_GetPrimaryPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.SetOwnPrimaryPaymentMethodRequest,
 *   !proto.payment_service.SetPrimaryPaymentMethodResponse>}
 */
const methodDescriptor_PaymentService_SetOwnPrimaryPaymentMethod = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/SetOwnPrimaryPaymentMethod',
  grpc.web.MethodType.UNARY,
  payment_service_pb.SetOwnPrimaryPaymentMethodRequest,
  payment_service_pb.SetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.SetOwnPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.SetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.SetOwnPrimaryPaymentMethodRequest,
 *   !proto.payment_service.SetPrimaryPaymentMethodResponse>}
 */
const methodInfo_PaymentService_SetOwnPrimaryPaymentMethod = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.SetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.SetOwnPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.SetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.SetOwnPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.SetPrimaryPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.SetPrimaryPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.setOwnPrimaryPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/SetOwnPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SetOwnPrimaryPaymentMethod,
      callback);
};


/**
 * @param {!proto.payment_service.SetOwnPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.SetPrimaryPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.setOwnPrimaryPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/SetOwnPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SetOwnPrimaryPaymentMethod);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.payment_service.SetPrimaryPaymentMethodRequest,
 *   !proto.payment_service.SetPrimaryPaymentMethodResponse>}
 */
const methodDescriptor_PaymentService_SetPrimaryPaymentMethod = new grpc.web.MethodDescriptor(
  '/gateway_service.PaymentService/SetPrimaryPaymentMethod',
  grpc.web.MethodType.UNARY,
  payment_service_pb.SetPrimaryPaymentMethodRequest,
  payment_service_pb.SetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.SetPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.SetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.payment_service.SetPrimaryPaymentMethodRequest,
 *   !proto.payment_service.SetPrimaryPaymentMethodResponse>}
 */
const methodInfo_PaymentService_SetPrimaryPaymentMethod = new grpc.web.AbstractClientBase.MethodInfo(
  payment_service_pb.SetPrimaryPaymentMethodResponse,
  /**
   * @param {!proto.payment_service.SetPrimaryPaymentMethodRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  payment_service_pb.SetPrimaryPaymentMethodResponse.deserializeBinary
);


/**
 * @param {!proto.payment_service.SetPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.payment_service.SetPrimaryPaymentMethodResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.payment_service.SetPrimaryPaymentMethodResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.PaymentServiceClient.prototype.setPrimaryPaymentMethod =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.PaymentService/SetPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SetPrimaryPaymentMethod,
      callback);
};


/**
 * @param {!proto.payment_service.SetPrimaryPaymentMethodRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.payment_service.SetPrimaryPaymentMethodResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.PaymentServicePromiseClient.prototype.setPrimaryPaymentMethod =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.PaymentService/SetPrimaryPaymentMethod',
      request,
      metadata || {},
      methodDescriptor_PaymentService_SetPrimaryPaymentMethod);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.VehicleListingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.VehicleListingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vehicle_listing_service.GetVehicleRequest,
 *   !proto.vehicle.Vehicle>}
 */
const methodDescriptor_VehicleListingService_GetVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.VehicleListingService/GetVehicle',
  grpc.web.MethodType.UNARY,
  vehicle_listing_service_pb.GetVehicleRequest,
  vehicle_pb.Vehicle,
  /**
   * @param {!proto.vehicle_listing_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  vehicle_pb.Vehicle.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.vehicle_listing_service.GetVehicleRequest,
 *   !proto.vehicle.Vehicle>}
 */
const methodInfo_VehicleListingService_GetVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  vehicle_pb.Vehicle,
  /**
   * @param {!proto.vehicle_listing_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  vehicle_pb.Vehicle.deserializeBinary
);


/**
 * @param {!proto.vehicle_listing_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.vehicle.Vehicle)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vehicle.Vehicle>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.VehicleListingServiceClient.prototype.getVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.VehicleListingService/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_VehicleListingService_GetVehicle,
      callback);
};


/**
 * @param {!proto.vehicle_listing_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vehicle.Vehicle>}
 *     Promise that resolves to the response
 */
proto.gateway_service.VehicleListingServicePromiseClient.prototype.getVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.VehicleListingService/GetVehicle',
      request,
      metadata || {},
      methodDescriptor_VehicleListingService_GetVehicle);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.FleetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.FleetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetVehicleRequest,
 *   !proto.fleet_service.GetVehicleResponse>}
 */
const methodDescriptor_FleetService_GetFleetVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetFleetVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetVehicleRequest,
  fleet_service_pb.GetVehicleResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetVehicleRequest,
 *   !proto.fleet_service.GetVehicleResponse>}
 */
const methodInfo_FleetService_GetFleetVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetVehicleResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getFleetVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetFleetVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetFleetVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.GetVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getFleetVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetFleetVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetFleetVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ListProfilesRequest,
 *   !proto.fleet_service.ListProfilesResponse>}
 */
const methodDescriptor_FleetService_ListProfiles = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ListProfiles',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ListProfilesRequest,
  fleet_service_pb.ListProfilesResponse,
  /**
   * @param {!proto.fleet_service.ListProfilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListProfilesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ListProfilesRequest,
 *   !proto.fleet_service.ListProfilesResponse>}
 */
const methodInfo_FleetService_ListProfiles = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ListProfilesResponse,
  /**
   * @param {!proto.fleet_service.ListProfilesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListProfilesResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ListProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ListProfilesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ListProfilesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.listProfiles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ListProfiles',
      request,
      metadata || {},
      methodDescriptor_FleetService_ListProfiles,
      callback);
};


/**
 * @param {!proto.fleet_service.ListProfilesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ListProfilesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.listProfiles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ListProfiles',
      request,
      metadata || {},
      methodDescriptor_FleetService_ListProfiles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateVehicleRequest,
 *   !proto.fleet_service.UpdateVehicleResponse>}
 */
const methodDescriptor_FleetService_UpdateVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/UpdateVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateVehicleRequest,
  fleet_service_pb.UpdateVehicleResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateVehicleRequest,
 *   !proto.fleet_service.UpdateVehicleResponse>}
 */
const methodInfo_FleetService_UpdateVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateVehicleResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.updateVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.updateVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.AssignVehicleRequest,
 *   !proto.fleet_service.AssignVehicleResponse>}
 */
const methodDescriptor_FleetService_AssignVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/AssignVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.AssignVehicleRequest,
  fleet_service_pb.AssignVehicleResponse,
  /**
   * @param {!proto.fleet_service.AssignVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.AssignVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.AssignVehicleRequest,
 *   !proto.fleet_service.AssignVehicleResponse>}
 */
const methodInfo_FleetService_AssignVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.AssignVehicleResponse,
  /**
   * @param {!proto.fleet_service.AssignVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.AssignVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.AssignVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.AssignVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.AssignVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.assignVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/AssignVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_AssignVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.AssignVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.AssignVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.assignVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/AssignVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_AssignVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UnassignVehicleRequest,
 *   !proto.fleet_service.UnassignVehicleResponse>}
 */
const methodDescriptor_FleetService_UnassignVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/UnassignVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UnassignVehicleRequest,
  fleet_service_pb.UnassignVehicleResponse,
  /**
   * @param {!proto.fleet_service.UnassignVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UnassignVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UnassignVehicleRequest,
 *   !proto.fleet_service.UnassignVehicleResponse>}
 */
const methodInfo_FleetService_UnassignVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UnassignVehicleResponse,
  /**
   * @param {!proto.fleet_service.UnassignVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UnassignVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UnassignVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UnassignVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UnassignVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.unassignVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/UnassignVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_UnassignVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.UnassignVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UnassignVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.unassignVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/UnassignVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_UnassignVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ConfirmVehicleRequest,
 *   !proto.fleet_service.ConfirmVehicleResponse>}
 */
const methodDescriptor_FleetService_ConfirmVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ConfirmVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ConfirmVehicleRequest,
  fleet_service_pb.ConfirmVehicleResponse,
  /**
   * @param {!proto.fleet_service.ConfirmVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ConfirmVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ConfirmVehicleRequest,
 *   !proto.fleet_service.ConfirmVehicleResponse>}
 */
const methodInfo_FleetService_ConfirmVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ConfirmVehicleResponse,
  /**
   * @param {!proto.fleet_service.ConfirmVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ConfirmVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ConfirmVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ConfirmVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.confirmVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ConfirmVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ConfirmVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.ConfirmVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ConfirmVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.confirmVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ConfirmVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ConfirmVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ValidateVinRequest,
 *   !proto.fleet_service.ValidateVinResponse>}
 */
const methodDescriptor_FleetService_ValidateVin = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ValidateVin',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ValidateVinRequest,
  fleet_service_pb.ValidateVinResponse,
  /**
   * @param {!proto.fleet_service.ValidateVinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ValidateVinResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ValidateVinRequest,
 *   !proto.fleet_service.ValidateVinResponse>}
 */
const methodInfo_FleetService_ValidateVin = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ValidateVinResponse,
  /**
   * @param {!proto.fleet_service.ValidateVinRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ValidateVinResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ValidateVinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ValidateVinResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ValidateVinResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.validateVin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ValidateVin',
      request,
      metadata || {},
      methodDescriptor_FleetService_ValidateVin,
      callback);
};


/**
 * @param {!proto.fleet_service.ValidateVinRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ValidateVinResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.validateVin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ValidateVin',
      request,
      metadata || {},
      methodDescriptor_FleetService_ValidateVin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateVehicleCustodyRequest,
 *   !proto.fleet_service.UpdateVehicleCustodyResponse>}
 */
const methodDescriptor_FleetService_UpdateVehicleCustody = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/UpdateVehicleCustody',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateVehicleCustodyRequest,
  fleet_service_pb.UpdateVehicleCustodyResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleCustodyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateVehicleCustodyRequest,
 *   !proto.fleet_service.UpdateVehicleCustodyResponse>}
 */
const methodInfo_FleetService_UpdateVehicleCustody = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateVehicleCustodyResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleCustodyResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateVehicleCustodyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateVehicleCustodyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.updateVehicleCustody =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicleCustody',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicleCustody,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateVehicleCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateVehicleCustodyResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.updateVehicleCustody =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicleCustody',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicleCustody);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetLatestCustodyRequest,
 *   !proto.fleet_service.GetLatestCustodyResponse>}
 */
const methodDescriptor_FleetService_GetLatestCustody = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetLatestCustody',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetLatestCustodyRequest,
  fleet_service_pb.GetLatestCustodyResponse,
  /**
   * @param {!proto.fleet_service.GetLatestCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetLatestCustodyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetLatestCustodyRequest,
 *   !proto.fleet_service.GetLatestCustodyResponse>}
 */
const methodInfo_FleetService_GetLatestCustody = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetLatestCustodyResponse,
  /**
   * @param {!proto.fleet_service.GetLatestCustodyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetLatestCustodyResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetLatestCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetLatestCustodyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetLatestCustodyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getLatestCustody =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetLatestCustody',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetLatestCustody,
      callback);
};


/**
 * @param {!proto.fleet_service.GetLatestCustodyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetLatestCustodyResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getLatestCustody =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetLatestCustody',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetLatestCustody);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetVehicleByIdRequest,
 *   !proto.fleet_service.GetVehicleByIdResponse>}
 */
const methodDescriptor_FleetService_GetVehicleById = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetVehicleById',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetVehicleByIdRequest,
  fleet_service_pb.GetVehicleByIdResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleByIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetVehicleByIdRequest,
 *   !proto.fleet_service.GetVehicleByIdResponse>}
 */
const methodInfo_FleetService_GetVehicleById = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetVehicleByIdResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleByIdResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetVehicleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetVehicleByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetVehicleByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getVehicleById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetVehicleById',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetVehicleById,
      callback);
};


/**
 * @param {!proto.fleet_service.GetVehicleByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetVehicleByIdResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getVehicleById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetVehicleById',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetVehicleById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetVehicleInfleetingRequest,
 *   !proto.fleet_service.GetVehicleInfleetingResponse>}
 */
const methodDescriptor_FleetService_GetVehicleInfleeting = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetVehicleInfleeting',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetVehicleInfleetingRequest,
  fleet_service_pb.GetVehicleInfleetingResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleInfleetingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleInfleetingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetVehicleInfleetingRequest,
 *   !proto.fleet_service.GetVehicleInfleetingResponse>}
 */
const methodInfo_FleetService_GetVehicleInfleeting = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetVehicleInfleetingResponse,
  /**
   * @param {!proto.fleet_service.GetVehicleInfleetingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetVehicleInfleetingResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetVehicleInfleetingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetVehicleInfleetingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getVehicleInfleeting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetVehicleInfleeting',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetVehicleInfleeting,
      callback);
};


/**
 * @param {!proto.fleet_service.GetVehicleInfleetingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetVehicleInfleetingResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getVehicleInfleeting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetVehicleInfleeting',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetVehicleInfleeting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.UpdateVehicleInfleetingRequest,
 *   !proto.fleet_service.UpdateVehicleInfleetingResponse>}
 */
const methodDescriptor_FleetService_UpdateVehicleInfleeting = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/UpdateVehicleInfleeting',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.UpdateVehicleInfleetingRequest,
  fleet_service_pb.UpdateVehicleInfleetingResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleInfleetingResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.UpdateVehicleInfleetingRequest,
 *   !proto.fleet_service.UpdateVehicleInfleetingResponse>}
 */
const methodInfo_FleetService_UpdateVehicleInfleeting = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.UpdateVehicleInfleetingResponse,
  /**
   * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.UpdateVehicleInfleetingResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.UpdateVehicleInfleetingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.UpdateVehicleInfleetingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.updateVehicleInfleeting =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicleInfleeting',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicleInfleeting,
      callback);
};


/**
 * @param {!proto.fleet_service.UpdateVehicleInfleetingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.UpdateVehicleInfleetingResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.updateVehicleInfleeting =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/UpdateVehicleInfleeting',
      request,
      metadata || {},
      methodDescriptor_FleetService_UpdateVehicleInfleeting);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ReturnVehicleRequest,
 *   !proto.fleet_service.ReturnVehicleResponse>}
 */
const methodDescriptor_FleetService_ReturnVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ReturnVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ReturnVehicleRequest,
  fleet_service_pb.ReturnVehicleResponse,
  /**
   * @param {!proto.fleet_service.ReturnVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ReturnVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ReturnVehicleRequest,
 *   !proto.fleet_service.ReturnVehicleResponse>}
 */
const methodInfo_FleetService_ReturnVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ReturnVehicleResponse,
  /**
   * @param {!proto.fleet_service.ReturnVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ReturnVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ReturnVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ReturnVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ReturnVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.returnVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ReturnVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ReturnVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.ReturnVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ReturnVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.returnVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ReturnVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ReturnVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.InitiateVehicleReturnRequest,
 *   !proto.fleet_service.InitiateVehicleReturnResponse>}
 */
const methodDescriptor_FleetService_InitiateVehicleReturn = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/InitiateVehicleReturn',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.InitiateVehicleReturnRequest,
  fleet_service_pb.InitiateVehicleReturnResponse,
  /**
   * @param {!proto.fleet_service.InitiateVehicleReturnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.InitiateVehicleReturnResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.InitiateVehicleReturnRequest,
 *   !proto.fleet_service.InitiateVehicleReturnResponse>}
 */
const methodInfo_FleetService_InitiateVehicleReturn = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.InitiateVehicleReturnResponse,
  /**
   * @param {!proto.fleet_service.InitiateVehicleReturnRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.InitiateVehicleReturnResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.InitiateVehicleReturnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.InitiateVehicleReturnResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.InitiateVehicleReturnResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.initiateVehicleReturn =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/InitiateVehicleReturn',
      request,
      metadata || {},
      methodDescriptor_FleetService_InitiateVehicleReturn,
      callback);
};


/**
 * @param {!proto.fleet_service.InitiateVehicleReturnRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.InitiateVehicleReturnResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.initiateVehicleReturn =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/InitiateVehicleReturn',
      request,
      metadata || {},
      methodDescriptor_FleetService_InitiateVehicleReturn);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ReturnAndPickupVehicleRequest,
 *   !proto.fleet_service.ReturnAndPickupVehicleResponse>}
 */
const methodDescriptor_FleetService_ReturnAndPickupVehicle = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ReturnAndPickupVehicle',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ReturnAndPickupVehicleRequest,
  fleet_service_pb.ReturnAndPickupVehicleResponse,
  /**
   * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ReturnAndPickupVehicleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ReturnAndPickupVehicleRequest,
 *   !proto.fleet_service.ReturnAndPickupVehicleResponse>}
 */
const methodInfo_FleetService_ReturnAndPickupVehicle = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ReturnAndPickupVehicleResponse,
  /**
   * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ReturnAndPickupVehicleResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ReturnAndPickupVehicleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ReturnAndPickupVehicleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.returnAndPickupVehicle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ReturnAndPickupVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ReturnAndPickupVehicle,
      callback);
};


/**
 * @param {!proto.fleet_service.ReturnAndPickupVehicleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ReturnAndPickupVehicleResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.returnAndPickupVehicle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ReturnAndPickupVehicle',
      request,
      metadata || {},
      methodDescriptor_FleetService_ReturnAndPickupVehicle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetOwnBuyItNowDetailsRequest,
 *   !proto.fleet_service.GetOwnBuyItNowDetailsResponse>}
 */
const methodDescriptor_FleetService_GetOwnBuyItNowDetails = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetOwnBuyItNowDetails',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetOwnBuyItNowDetailsRequest,
  fleet_service_pb.GetOwnBuyItNowDetailsResponse,
  /**
   * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetOwnBuyItNowDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetOwnBuyItNowDetailsRequest,
 *   !proto.fleet_service.GetOwnBuyItNowDetailsResponse>}
 */
const methodInfo_FleetService_GetOwnBuyItNowDetails = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetOwnBuyItNowDetailsResponse,
  /**
   * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetOwnBuyItNowDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetOwnBuyItNowDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetOwnBuyItNowDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getOwnBuyItNowDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetOwnBuyItNowDetails',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetOwnBuyItNowDetails,
      callback);
};


/**
 * @param {!proto.fleet_service.GetOwnBuyItNowDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetOwnBuyItNowDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getOwnBuyItNowDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetOwnBuyItNowDetails',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetOwnBuyItNowDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetPrePaidMileageRateRequest,
 *   !proto.fleet_service.GetPrePaidMileageRateResponse>}
 */
const methodDescriptor_FleetService_GetPrePaidMileageRate = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetPrePaidMileageRate',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetPrePaidMileageRateRequest,
  fleet_service_pb.GetPrePaidMileageRateResponse,
  /**
   * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetPrePaidMileageRateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetPrePaidMileageRateRequest,
 *   !proto.fleet_service.GetPrePaidMileageRateResponse>}
 */
const methodInfo_FleetService_GetPrePaidMileageRate = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetPrePaidMileageRateResponse,
  /**
   * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetPrePaidMileageRateResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetPrePaidMileageRateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetPrePaidMileageRateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getPrePaidMileageRate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetPrePaidMileageRate',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetPrePaidMileageRate,
      callback);
};


/**
 * @param {!proto.fleet_service.GetPrePaidMileageRateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetPrePaidMileageRateResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getPrePaidMileageRate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetPrePaidMileageRate',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetPrePaidMileageRate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetSubscriptionMileageRequest,
 *   !proto.fleet_service.GetSubscriptionMileageResponse>}
 */
const methodDescriptor_FleetService_GetSubscriptionMileage = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetSubscriptionMileage',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetSubscriptionMileageRequest,
  fleet_service_pb.GetSubscriptionMileageResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetSubscriptionMileageRequest,
 *   !proto.fleet_service.GetSubscriptionMileageResponse>}
 */
const methodInfo_FleetService_GetSubscriptionMileage = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetSubscriptionMileageResponse,
  /**
   * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetSubscriptionMileageResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetSubscriptionMileageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetSubscriptionMileageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getSubscriptionMileage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetSubscriptionMileage',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetSubscriptionMileage,
      callback);
};


/**
 * @param {!proto.fleet_service.GetSubscriptionMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetSubscriptionMileageResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getSubscriptionMileage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetSubscriptionMileage',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetSubscriptionMileage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.CreateInspectionReportRequest,
 *   !proto.fleet_service.CreateInspectionReportResponse>}
 */
const methodDescriptor_FleetService_CreateInspectionReport = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/CreateInspectionReport',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.CreateInspectionReportRequest,
  fleet_service_pb.CreateInspectionReportResponse,
  /**
   * @param {!proto.fleet_service.CreateInspectionReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.CreateInspectionReportResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.CreateInspectionReportRequest,
 *   !proto.fleet_service.CreateInspectionReportResponse>}
 */
const methodInfo_FleetService_CreateInspectionReport = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.CreateInspectionReportResponse,
  /**
   * @param {!proto.fleet_service.CreateInspectionReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.CreateInspectionReportResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.CreateInspectionReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.CreateInspectionReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.CreateInspectionReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.createInspectionReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/CreateInspectionReport',
      request,
      metadata || {},
      methodDescriptor_FleetService_CreateInspectionReport,
      callback);
};


/**
 * @param {!proto.fleet_service.CreateInspectionReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.CreateInspectionReportResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.createInspectionReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/CreateInspectionReport',
      request,
      metadata || {},
      methodDescriptor_FleetService_CreateInspectionReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetPresignedWritePhotoURLRequest,
 *   !proto.fleet_service.GetPresignedWritePhotoURLResponse>}
 */
const methodDescriptor_FleetService_GetPresignedWritePhotoURL = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetPresignedWritePhotoURL',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetPresignedWritePhotoURLRequest,
  fleet_service_pb.GetPresignedWritePhotoURLResponse,
  /**
   * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetPresignedWritePhotoURLResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetPresignedWritePhotoURLRequest,
 *   !proto.fleet_service.GetPresignedWritePhotoURLResponse>}
 */
const methodInfo_FleetService_GetPresignedWritePhotoURL = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetPresignedWritePhotoURLResponse,
  /**
   * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetPresignedWritePhotoURLResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetPresignedWritePhotoURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetPresignedWritePhotoURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getPresignedWritePhotoURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetPresignedWritePhotoURL',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetPresignedWritePhotoURL,
      callback);
};


/**
 * @param {!proto.fleet_service.GetPresignedWritePhotoURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetPresignedWritePhotoURLResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getPresignedWritePhotoURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetPresignedWritePhotoURL',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetPresignedWritePhotoURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetInspectionSignatureWriteURLRequest,
 *   !proto.fleet_service.GetInspectionSignatureWriteURLResponse>}
 */
const methodDescriptor_FleetService_GetInspectionSignatureWriteURL = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetInspectionSignatureWriteURL',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetInspectionSignatureWriteURLRequest,
  fleet_service_pb.GetInspectionSignatureWriteURLResponse,
  /**
   * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetInspectionSignatureWriteURLResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetInspectionSignatureWriteURLRequest,
 *   !proto.fleet_service.GetInspectionSignatureWriteURLResponse>}
 */
const methodInfo_FleetService_GetInspectionSignatureWriteURL = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetInspectionSignatureWriteURLResponse,
  /**
   * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetInspectionSignatureWriteURLResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetInspectionSignatureWriteURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetInspectionSignatureWriteURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getInspectionSignatureWriteURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetInspectionSignatureWriteURL',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetInspectionSignatureWriteURL,
      callback);
};


/**
 * @param {!proto.fleet_service.GetInspectionSignatureWriteURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetInspectionSignatureWriteURLResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getInspectionSignatureWriteURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetInspectionSignatureWriteURL',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetInspectionSignatureWriteURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.ListInspectionReportsRequest,
 *   !proto.fleet_service.ListInspectionReportsResponse>}
 */
const methodDescriptor_FleetService_ListInspectionReports = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/ListInspectionReports',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.ListInspectionReportsRequest,
  fleet_service_pb.ListInspectionReportsResponse,
  /**
   * @param {!proto.fleet_service.ListInspectionReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListInspectionReportsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.ListInspectionReportsRequest,
 *   !proto.fleet_service.ListInspectionReportsResponse>}
 */
const methodInfo_FleetService_ListInspectionReports = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.ListInspectionReportsResponse,
  /**
   * @param {!proto.fleet_service.ListInspectionReportsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.ListInspectionReportsResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.ListInspectionReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.ListInspectionReportsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.ListInspectionReportsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.listInspectionReports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/ListInspectionReports',
      request,
      metadata || {},
      methodDescriptor_FleetService_ListInspectionReports,
      callback);
};


/**
 * @param {!proto.fleet_service.ListInspectionReportsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.ListInspectionReportsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.listInspectionReports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/ListInspectionReports',
      request,
      metadata || {},
      methodDescriptor_FleetService_ListInspectionReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.SignInspectionReportRequest,
 *   !proto.fleet_service.SignInspectionReportResponse>}
 */
const methodDescriptor_FleetService_SignInspectionReport = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/SignInspectionReport',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.SignInspectionReportRequest,
  fleet_service_pb.SignInspectionReportResponse,
  /**
   * @param {!proto.fleet_service.SignInspectionReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.SignInspectionReportResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.SignInspectionReportRequest,
 *   !proto.fleet_service.SignInspectionReportResponse>}
 */
const methodInfo_FleetService_SignInspectionReport = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.SignInspectionReportResponse,
  /**
   * @param {!proto.fleet_service.SignInspectionReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.SignInspectionReportResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.SignInspectionReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.SignInspectionReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.SignInspectionReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.signInspectionReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/SignInspectionReport',
      request,
      metadata || {},
      methodDescriptor_FleetService_SignInspectionReport,
      callback);
};


/**
 * @param {!proto.fleet_service.SignInspectionReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.SignInspectionReportResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.signInspectionReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/SignInspectionReport',
      request,
      metadata || {},
      methodDescriptor_FleetService_SignInspectionReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.AddInspectionPhotosRequest,
 *   !proto.fleet_service.AddInspectionPhotosResponse>}
 */
const methodDescriptor_FleetService_AddInspectionPhotos = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/AddInspectionPhotos',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.AddInspectionPhotosRequest,
  fleet_service_pb.AddInspectionPhotosResponse,
  /**
   * @param {!proto.fleet_service.AddInspectionPhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.AddInspectionPhotosResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.AddInspectionPhotosRequest,
 *   !proto.fleet_service.AddInspectionPhotosResponse>}
 */
const methodInfo_FleetService_AddInspectionPhotos = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.AddInspectionPhotosResponse,
  /**
   * @param {!proto.fleet_service.AddInspectionPhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.AddInspectionPhotosResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.AddInspectionPhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.AddInspectionPhotosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.AddInspectionPhotosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.addInspectionPhotos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/AddInspectionPhotos',
      request,
      metadata || {},
      methodDescriptor_FleetService_AddInspectionPhotos,
      callback);
};


/**
 * @param {!proto.fleet_service.AddInspectionPhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.AddInspectionPhotosResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.addInspectionPhotos =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/AddInspectionPhotos',
      request,
      metadata || {},
      methodDescriptor_FleetService_AddInspectionPhotos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.GetInspectionPhotosRequest,
 *   !proto.fleet_service.GetInspectionPhotosResponse>}
 */
const methodDescriptor_FleetService_GetInspectionPhotos = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/GetInspectionPhotos',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.GetInspectionPhotosRequest,
  fleet_service_pb.GetInspectionPhotosResponse,
  /**
   * @param {!proto.fleet_service.GetInspectionPhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetInspectionPhotosResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.GetInspectionPhotosRequest,
 *   !proto.fleet_service.GetInspectionPhotosResponse>}
 */
const methodInfo_FleetService_GetInspectionPhotos = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.GetInspectionPhotosResponse,
  /**
   * @param {!proto.fleet_service.GetInspectionPhotosRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.GetInspectionPhotosResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.GetInspectionPhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.GetInspectionPhotosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.GetInspectionPhotosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.getInspectionPhotos =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/GetInspectionPhotos',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetInspectionPhotos,
      callback);
};


/**
 * @param {!proto.fleet_service.GetInspectionPhotosRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.GetInspectionPhotosResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.getInspectionPhotos =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/GetInspectionPhotos',
      request,
      metadata || {},
      methodDescriptor_FleetService_GetInspectionPhotos);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.LogMilesPurchasedRequest,
 *   !proto.fleet_service.LogMilesPurchasedResponse>}
 */
const methodDescriptor_FleetService_LogMilesPurchased = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/LogMilesPurchased',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.LogMilesPurchasedRequest,
  fleet_service_pb.LogMilesPurchasedResponse,
  /**
   * @param {!proto.fleet_service.LogMilesPurchasedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.LogMilesPurchasedResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.LogMilesPurchasedRequest,
 *   !proto.fleet_service.LogMilesPurchasedResponse>}
 */
const methodInfo_FleetService_LogMilesPurchased = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.LogMilesPurchasedResponse,
  /**
   * @param {!proto.fleet_service.LogMilesPurchasedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.LogMilesPurchasedResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.LogMilesPurchasedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.LogMilesPurchasedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.LogMilesPurchasedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.logMilesPurchased =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/LogMilesPurchased',
      request,
      metadata || {},
      methodDescriptor_FleetService_LogMilesPurchased,
      callback);
};


/**
 * @param {!proto.fleet_service.LogMilesPurchasedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.LogMilesPurchasedResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.logMilesPurchased =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/LogMilesPurchased',
      request,
      metadata || {},
      methodDescriptor_FleetService_LogMilesPurchased);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.fleet_service.DeleteMilesPurchasedLogRequest,
 *   !proto.fleet_service.DeleteMilesPurchasedLogResponse>}
 */
const methodDescriptor_FleetService_DeleteMilesPurchasedLog = new grpc.web.MethodDescriptor(
  '/gateway_service.FleetService/DeleteMilesPurchasedLog',
  grpc.web.MethodType.UNARY,
  fleet_service_pb.DeleteMilesPurchasedLogRequest,
  fleet_service_pb.DeleteMilesPurchasedLogResponse,
  /**
   * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.DeleteMilesPurchasedLogResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.fleet_service.DeleteMilesPurchasedLogRequest,
 *   !proto.fleet_service.DeleteMilesPurchasedLogResponse>}
 */
const methodInfo_FleetService_DeleteMilesPurchasedLog = new grpc.web.AbstractClientBase.MethodInfo(
  fleet_service_pb.DeleteMilesPurchasedLogResponse,
  /**
   * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  fleet_service_pb.DeleteMilesPurchasedLogResponse.deserializeBinary
);


/**
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.fleet_service.DeleteMilesPurchasedLogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.fleet_service.DeleteMilesPurchasedLogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.FleetServiceClient.prototype.deleteMilesPurchasedLog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.FleetService/DeleteMilesPurchasedLog',
      request,
      metadata || {},
      methodDescriptor_FleetService_DeleteMilesPurchasedLog,
      callback);
};


/**
 * @param {!proto.fleet_service.DeleteMilesPurchasedLogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.fleet_service.DeleteMilesPurchasedLogResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.FleetServicePromiseClient.prototype.deleteMilesPurchasedLog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.FleetService/DeleteMilesPurchasedLog',
      request,
      metadata || {},
      methodDescriptor_FleetService_DeleteMilesPurchasedLog);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.InspectionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.InspectionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.BeginInspectionRequest,
 *   !proto.inspection.BeginInspectionResponse>}
 */
const methodDescriptor_InspectionService_BeginInspection = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/BeginInspection',
  grpc.web.MethodType.UNARY,
  inspection_pb.BeginInspectionRequest,
  inspection_pb.BeginInspectionResponse,
  /**
   * @param {!proto.inspection.BeginInspectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.BeginInspectionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.BeginInspectionRequest,
 *   !proto.inspection.BeginInspectionResponse>}
 */
const methodInfo_InspectionService_BeginInspection = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.BeginInspectionResponse,
  /**
   * @param {!proto.inspection.BeginInspectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.BeginInspectionResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.BeginInspectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.BeginInspectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.BeginInspectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.beginInspection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/BeginInspection',
      request,
      metadata || {},
      methodDescriptor_InspectionService_BeginInspection,
      callback);
};


/**
 * @param {!proto.inspection.BeginInspectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.BeginInspectionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.beginInspection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/BeginInspection',
      request,
      metadata || {},
      methodDescriptor_InspectionService_BeginInspection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.GetInspectionResultsRequest,
 *   !proto.inspection.GetInspectionResultsResponse>}
 */
const methodDescriptor_InspectionService_GetInspectionResults = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/GetInspectionResults',
  grpc.web.MethodType.UNARY,
  inspection_pb.GetInspectionResultsRequest,
  inspection_pb.GetInspectionResultsResponse,
  /**
   * @param {!proto.inspection.GetInspectionResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.GetInspectionResultsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.GetInspectionResultsRequest,
 *   !proto.inspection.GetInspectionResultsResponse>}
 */
const methodInfo_InspectionService_GetInspectionResults = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.GetInspectionResultsResponse,
  /**
   * @param {!proto.inspection.GetInspectionResultsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.GetInspectionResultsResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.GetInspectionResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.GetInspectionResultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.GetInspectionResultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.getInspectionResults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/GetInspectionResults',
      request,
      metadata || {},
      methodDescriptor_InspectionService_GetInspectionResults,
      callback);
};


/**
 * @param {!proto.inspection.GetInspectionResultsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.GetInspectionResultsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.getInspectionResults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/GetInspectionResults',
      request,
      metadata || {},
      methodDescriptor_InspectionService_GetInspectionResults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.SubmitCustomerMileageRequest,
 *   !proto.inspection.SubmitCustomerMileageResponse>}
 */
const methodDescriptor_InspectionService_SubmitCustomerMileage = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/SubmitCustomerMileage',
  grpc.web.MethodType.UNARY,
  inspection_pb.SubmitCustomerMileageRequest,
  inspection_pb.SubmitCustomerMileageResponse,
  /**
   * @param {!proto.inspection.SubmitCustomerMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.SubmitCustomerMileageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.SubmitCustomerMileageRequest,
 *   !proto.inspection.SubmitCustomerMileageResponse>}
 */
const methodInfo_InspectionService_SubmitCustomerMileage = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.SubmitCustomerMileageResponse,
  /**
   * @param {!proto.inspection.SubmitCustomerMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.SubmitCustomerMileageResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.SubmitCustomerMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.SubmitCustomerMileageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.SubmitCustomerMileageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.submitCustomerMileage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/SubmitCustomerMileage',
      request,
      metadata || {},
      methodDescriptor_InspectionService_SubmitCustomerMileage,
      callback);
};


/**
 * @param {!proto.inspection.SubmitCustomerMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.SubmitCustomerMileageResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.submitCustomerMileage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/SubmitCustomerMileage',
      request,
      metadata || {},
      methodDescriptor_InspectionService_SubmitCustomerMileage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.StartInspectionCustomerRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodDescriptor_InspectionService_StartInspectionCustomer = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/StartInspectionCustomer',
  grpc.web.MethodType.UNARY,
  inspection_pb.StartInspectionCustomerRequest,
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.StartInspectionCustomerRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodInfo_InspectionService_StartInspectionCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.StartInspectionCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.StartInspectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.StartInspectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.startInspectionCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionCustomer',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionCustomer,
      callback);
};


/**
 * @param {!proto.inspection.StartInspectionCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.StartInspectionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.startInspectionCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionCustomer',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.StartInspectionDealerRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodDescriptor_InspectionService_StartInspectionDealer = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/StartInspectionDealer',
  grpc.web.MethodType.UNARY,
  inspection_pb.StartInspectionDealerRequest,
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionDealerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.StartInspectionDealerRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodInfo_InspectionService_StartInspectionDealer = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionDealerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.StartInspectionDealerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.StartInspectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.StartInspectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.startInspectionDealer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionDealer',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionDealer,
      callback);
};


/**
 * @param {!proto.inspection.StartInspectionDealerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.StartInspectionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.startInspectionDealer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionDealer',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionDealer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.StartInspectionFleetRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodDescriptor_InspectionService_StartInspectionFleet = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/StartInspectionFleet',
  grpc.web.MethodType.UNARY,
  inspection_pb.StartInspectionFleetRequest,
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionFleetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.StartInspectionFleetRequest,
 *   !proto.inspection.StartInspectionResponse>}
 */
const methodInfo_InspectionService_StartInspectionFleet = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.StartInspectionResponse,
  /**
   * @param {!proto.inspection.StartInspectionFleetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.StartInspectionResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.StartInspectionFleetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.StartInspectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.StartInspectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.startInspectionFleet =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionFleet',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionFleet,
      callback);
};


/**
 * @param {!proto.inspection.StartInspectionFleetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.StartInspectionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.startInspectionFleet =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/StartInspectionFleet',
      request,
      metadata || {},
      methodDescriptor_InspectionService_StartInspectionFleet);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.GetInspectionStatusRequest,
 *   !proto.inspection.GetInspectionStatusResponse>}
 */
const methodDescriptor_InspectionService_GetInspectionStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/GetInspectionStatus',
  grpc.web.MethodType.SERVER_STREAMING,
  inspection_pb.GetInspectionStatusRequest,
  inspection_pb.GetInspectionStatusResponse,
  /**
   * @param {!proto.inspection.GetInspectionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.GetInspectionStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.GetInspectionStatusRequest,
 *   !proto.inspection.GetInspectionStatusResponse>}
 */
const methodInfo_InspectionService_GetInspectionStatus = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.GetInspectionStatusResponse,
  /**
   * @param {!proto.inspection.GetInspectionStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.GetInspectionStatusResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.GetInspectionStatusRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.GetInspectionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.getInspectionStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.InspectionService/GetInspectionStatus',
      request,
      metadata || {},
      methodDescriptor_InspectionService_GetInspectionStatus);
};


/**
 * @param {!proto.inspection.GetInspectionStatusRequest} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.GetInspectionStatusResponse>}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.getInspectionStatus =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/gateway_service.InspectionService/GetInspectionStatus',
      request,
      metadata || {},
      methodDescriptor_InspectionService_GetInspectionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.inspection.CheckVehicleInspectionRequest,
 *   !proto.inspection.CheckVehicleInspectionResponse>}
 */
const methodDescriptor_InspectionService_CheckVehicleInspection = new grpc.web.MethodDescriptor(
  '/gateway_service.InspectionService/CheckVehicleInspection',
  grpc.web.MethodType.UNARY,
  inspection_pb.CheckVehicleInspectionRequest,
  inspection_pb.CheckVehicleInspectionResponse,
  /**
   * @param {!proto.inspection.CheckVehicleInspectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.CheckVehicleInspectionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.inspection.CheckVehicleInspectionRequest,
 *   !proto.inspection.CheckVehicleInspectionResponse>}
 */
const methodInfo_InspectionService_CheckVehicleInspection = new grpc.web.AbstractClientBase.MethodInfo(
  inspection_pb.CheckVehicleInspectionResponse,
  /**
   * @param {!proto.inspection.CheckVehicleInspectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  inspection_pb.CheckVehicleInspectionResponse.deserializeBinary
);


/**
 * @param {!proto.inspection.CheckVehicleInspectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.inspection.CheckVehicleInspectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.inspection.CheckVehicleInspectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InspectionServiceClient.prototype.checkVehicleInspection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InspectionService/CheckVehicleInspection',
      request,
      metadata || {},
      methodDescriptor_InspectionService_CheckVehicleInspection,
      callback);
};


/**
 * @param {!proto.inspection.CheckVehicleInspectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.inspection.CheckVehicleInspectionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InspectionServicePromiseClient.prototype.checkVehicleInspection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InspectionService/CheckVehicleInspection',
      request,
      metadata || {},
      methodDescriptor_InspectionService_CheckVehicleInspection);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.InsuranceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.InsuranceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.UpsertOwnInsurancePoliciesRequest,
 *   !proto.insurance_service.UpsertInsurancePoliciesResponse>}
 */
const methodDescriptor_InsuranceService_UpsertOwnInsurancePolicies = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/UpsertOwnInsurancePolicies',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.UpsertOwnInsurancePoliciesRequest,
  insurance_service_pb.UpsertInsurancePoliciesResponse,
  /**
   * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.UpsertInsurancePoliciesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.UpsertOwnInsurancePoliciesRequest,
 *   !proto.insurance_service.UpsertInsurancePoliciesResponse>}
 */
const methodInfo_InsuranceService_UpsertOwnInsurancePolicies = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.UpsertInsurancePoliciesResponse,
  /**
   * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.UpsertInsurancePoliciesResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.UpsertInsurancePoliciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.UpsertInsurancePoliciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.upsertOwnInsurancePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/UpsertOwnInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_UpsertOwnInsurancePolicies,
      callback);
};


/**
 * @param {!proto.insurance_service.UpsertOwnInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.UpsertInsurancePoliciesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.upsertOwnInsurancePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/UpsertOwnInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_UpsertOwnInsurancePolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.CreateInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodDescriptor_InsuranceService_CreateInsuranceQuote = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/CreateInsuranceQuote',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.CreateInsuranceQuoteRequest,
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.CreateInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodInfo_InsuranceService_CreateInsuranceQuote = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuranceQuoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuranceQuoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.createInsuranceQuote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/CreateInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CreateInsuranceQuote,
      callback);
};


/**
 * @param {!proto.insurance_service.CreateInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuranceQuoteResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.createInsuranceQuote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/CreateInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CreateInsuranceQuote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodDescriptor_InsuranceService_GetInsuranceQuote = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetInsuranceQuote',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsuranceQuoteRequest,
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsuranceQuoteRequest,
 *   !proto.insurance_service.GetInsuranceQuoteResponse>}
 */
const methodInfo_InsuranceService_GetInsuranceQuote = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceQuoteResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuranceQuoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuranceQuoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getInsuranceQuote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuranceQuote,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuranceQuoteResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getInsuranceQuote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuranceQuote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetOwnInsurancePoliciesRequest,
 *   !proto.insurance_service.GetInsurancePoliciesResponse>}
 */
const methodDescriptor_InsuranceService_GetOwnInsurancePolicies = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetOwnInsurancePolicies',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetOwnInsurancePoliciesRequest,
  insurance_service_pb.GetInsurancePoliciesResponse,
  /**
   * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePoliciesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetOwnInsurancePoliciesRequest,
 *   !proto.insurance_service.GetInsurancePoliciesResponse>}
 */
const methodInfo_InsuranceService_GetOwnInsurancePolicies = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsurancePoliciesResponse,
  /**
   * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePoliciesResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsurancePoliciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsurancePoliciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getOwnInsurancePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetOwnInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetOwnInsurancePolicies,
      callback);
};


/**
 * @param {!proto.insurance_service.GetOwnInsurancePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsurancePoliciesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getOwnInsurancePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetOwnInsurancePolicies',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetOwnInsurancePolicies);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsurancePolicyRequest,
 *   !proto.insurance_service.GetInsurancePolicyResponse>}
 */
const methodDescriptor_InsuranceService_GetInsurancePolicy = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetInsurancePolicy',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsurancePolicyRequest,
  insurance_service_pb.GetInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.GetInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePolicyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsurancePolicyRequest,
 *   !proto.insurance_service.GetInsurancePolicyResponse>}
 */
const methodInfo_InsuranceService_GetInsurancePolicy = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.GetInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsurancePolicyResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsurancePolicyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsurancePolicyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getInsurancePolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsurancePolicy,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsurancePolicyResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getInsurancePolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsurancePolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.QualifyForLibertyInsuranceRequest,
 *   !proto.insurance_service.QualifyForLibertyInsuranceResponse>}
 */
const methodDescriptor_InsuranceService_QualifyForLibertyInsurance = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/QualifyForLibertyInsurance',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.QualifyForLibertyInsuranceRequest,
  insurance_service_pb.QualifyForLibertyInsuranceResponse,
  /**
   * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.QualifyForLibertyInsuranceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.QualifyForLibertyInsuranceRequest,
 *   !proto.insurance_service.QualifyForLibertyInsuranceResponse>}
 */
const methodInfo_InsuranceService_QualifyForLibertyInsurance = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.QualifyForLibertyInsuranceResponse,
  /**
   * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.QualifyForLibertyInsuranceResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.QualifyForLibertyInsuranceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.QualifyForLibertyInsuranceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.qualifyForLibertyInsurance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/QualifyForLibertyInsurance',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_QualifyForLibertyInsurance,
      callback);
};


/**
 * @param {!proto.insurance_service.QualifyForLibertyInsuranceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.QualifyForLibertyInsuranceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.qualifyForLibertyInsurance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/QualifyForLibertyInsurance',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_QualifyForLibertyInsurance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsuredDriversRequest,
 *   !proto.insurance_service.GetInsuredDriversResponse>}
 */
const methodDescriptor_InsuranceService_GetInsuredDrivers = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetInsuredDrivers',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsuredDriversRequest,
  insurance_service_pb.GetInsuredDriversResponse,
  /**
   * @param {!proto.insurance_service.GetInsuredDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuredDriversResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsuredDriversRequest,
 *   !proto.insurance_service.GetInsuredDriversResponse>}
 */
const methodInfo_InsuranceService_GetInsuredDrivers = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuredDriversResponse,
  /**
   * @param {!proto.insurance_service.GetInsuredDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuredDriversResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsuredDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuredDriversResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuredDriversResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getInsuredDrivers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuredDrivers',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuredDrivers,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsuredDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuredDriversResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getInsuredDrivers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuredDrivers',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuredDrivers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetInsuranceStatusRequest,
 *   !proto.insurance_service.GetInsuranceStatusResponse>}
 */
const methodDescriptor_InsuranceService_GetInsuranceStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetInsuranceStatus',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetInsuranceStatusRequest,
  insurance_service_pb.GetInsuranceStatusResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetInsuranceStatusRequest,
 *   !proto.insurance_service.GetInsuranceStatusResponse>}
 */
const methodInfo_InsuranceService_GetInsuranceStatus = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetInsuranceStatusResponse,
  /**
   * @param {!proto.insurance_service.GetInsuranceStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetInsuranceStatusResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetInsuranceStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetInsuranceStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetInsuranceStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getInsuranceStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuranceStatus',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuranceStatus,
      callback);
};


/**
 * @param {!proto.insurance_service.GetInsuranceStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetInsuranceStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getInsuranceStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetInsuranceStatus',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetInsuranceStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.CreateAllInsuranceQuotesRequest,
 *   !proto.insurance_service.CreateAllInsuranceQuotesResponse>}
 */
const methodDescriptor_InsuranceService_CreateAllInsuranceQuotes = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/CreateAllInsuranceQuotes',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.CreateAllInsuranceQuotesRequest,
  insurance_service_pb.CreateAllInsuranceQuotesResponse,
  /**
   * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.CreateAllInsuranceQuotesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.CreateAllInsuranceQuotesRequest,
 *   !proto.insurance_service.CreateAllInsuranceQuotesResponse>}
 */
const methodInfo_InsuranceService_CreateAllInsuranceQuotes = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.CreateAllInsuranceQuotesResponse,
  /**
   * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.CreateAllInsuranceQuotesResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.CreateAllInsuranceQuotesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.CreateAllInsuranceQuotesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.createAllInsuranceQuotes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/CreateAllInsuranceQuotes',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CreateAllInsuranceQuotes,
      callback);
};


/**
 * @param {!proto.insurance_service.CreateAllInsuranceQuotesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.CreateAllInsuranceQuotesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.createAllInsuranceQuotes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/CreateAllInsuranceQuotes',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CreateAllInsuranceQuotes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.SelectInsuranceQuoteRequest,
 *   !proto.insurance_service.SelectInsuranceQuoteResponse>}
 */
const methodDescriptor_InsuranceService_SelectInsuranceQuote = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/SelectInsuranceQuote',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.SelectInsuranceQuoteRequest,
  insurance_service_pb.SelectInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.SelectInsuranceQuoteResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.SelectInsuranceQuoteRequest,
 *   !proto.insurance_service.SelectInsuranceQuoteResponse>}
 */
const methodInfo_InsuranceService_SelectInsuranceQuote = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.SelectInsuranceQuoteResponse,
  /**
   * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.SelectInsuranceQuoteResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.SelectInsuranceQuoteResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.SelectInsuranceQuoteResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.selectInsuranceQuote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/SelectInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_SelectInsuranceQuote,
      callback);
};


/**
 * @param {!proto.insurance_service.SelectInsuranceQuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.SelectInsuranceQuoteResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.selectInsuranceQuote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/SelectInsuranceQuote',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_SelectInsuranceQuote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.CancelInsurancePolicyRequest,
 *   !proto.insurance_service.CancelInsurancePolicyResponse>}
 */
const methodDescriptor_InsuranceService_CancelInsurancePolicy = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/CancelInsurancePolicy',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.CancelInsurancePolicyRequest,
  insurance_service_pb.CancelInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.CancelInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.CancelInsurancePolicyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.CancelInsurancePolicyRequest,
 *   !proto.insurance_service.CancelInsurancePolicyResponse>}
 */
const methodInfo_InsuranceService_CancelInsurancePolicy = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.CancelInsurancePolicyResponse,
  /**
   * @param {!proto.insurance_service.CancelInsurancePolicyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.CancelInsurancePolicyResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.CancelInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.CancelInsurancePolicyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.CancelInsurancePolicyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.cancelInsurancePolicy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/CancelInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CancelInsurancePolicy,
      callback);
};


/**
 * @param {!proto.insurance_service.CancelInsurancePolicyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.CancelInsurancePolicyResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.cancelInsurancePolicy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/CancelInsurancePolicy',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_CancelInsurancePolicy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.GetLatestQuoteGroupRequest,
 *   !proto.insurance_service.GetLatestQuoteGroupResponse>}
 */
const methodDescriptor_InsuranceService_GetLatestQuoteGroup = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/GetLatestQuoteGroup',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.GetLatestQuoteGroupRequest,
  insurance_service_pb.GetLatestQuoteGroupResponse,
  /**
   * @param {!proto.insurance_service.GetLatestQuoteGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetLatestQuoteGroupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.GetLatestQuoteGroupRequest,
 *   !proto.insurance_service.GetLatestQuoteGroupResponse>}
 */
const methodInfo_InsuranceService_GetLatestQuoteGroup = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.GetLatestQuoteGroupResponse,
  /**
   * @param {!proto.insurance_service.GetLatestQuoteGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.GetLatestQuoteGroupResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.GetLatestQuoteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.GetLatestQuoteGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.GetLatestQuoteGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.getLatestQuoteGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetLatestQuoteGroup',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetLatestQuoteGroup,
      callback);
};


/**
 * @param {!proto.insurance_service.GetLatestQuoteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.GetLatestQuoteGroupResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.getLatestQuoteGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/GetLatestQuoteGroup',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_GetLatestQuoteGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.insurance_service.ModifyInsuranceDriversRequest,
 *   !proto.insurance_service.ModifyInsuranceDriversResponse>}
 */
const methodDescriptor_InsuranceService_ModifyInsuranceDrivers = new grpc.web.MethodDescriptor(
  '/gateway_service.InsuranceService/ModifyInsuranceDrivers',
  grpc.web.MethodType.UNARY,
  insurance_service_pb.ModifyInsuranceDriversRequest,
  insurance_service_pb.ModifyInsuranceDriversResponse,
  /**
   * @param {!proto.insurance_service.ModifyInsuranceDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.ModifyInsuranceDriversResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.insurance_service.ModifyInsuranceDriversRequest,
 *   !proto.insurance_service.ModifyInsuranceDriversResponse>}
 */
const methodInfo_InsuranceService_ModifyInsuranceDrivers = new grpc.web.AbstractClientBase.MethodInfo(
  insurance_service_pb.ModifyInsuranceDriversResponse,
  /**
   * @param {!proto.insurance_service.ModifyInsuranceDriversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  insurance_service_pb.ModifyInsuranceDriversResponse.deserializeBinary
);


/**
 * @param {!proto.insurance_service.ModifyInsuranceDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.insurance_service.ModifyInsuranceDriversResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.insurance_service.ModifyInsuranceDriversResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.InsuranceServiceClient.prototype.modifyInsuranceDrivers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.InsuranceService/ModifyInsuranceDrivers',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_ModifyInsuranceDrivers,
      callback);
};


/**
 * @param {!proto.insurance_service.ModifyInsuranceDriversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.insurance_service.ModifyInsuranceDriversResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.InsuranceServicePromiseClient.prototype.modifyInsuranceDrivers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.InsuranceService/ModifyInsuranceDrivers',
      request,
      metadata || {},
      methodDescriptor_InsuranceService_ModifyInsuranceDrivers);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.SubscriptionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.SubscriptionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetActiveOrLatestSubscriptionRequest,
 *   !proto.subscription_service.GetSubscriptionResponse>}
 */
const methodDescriptor_SubscriptionService_GetOwnActiveOrLatestSubscription = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetOwnActiveOrLatestSubscription',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetActiveOrLatestSubscriptionRequest,
  subscription_service_pb.GetSubscriptionResponse,
  /**
   * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetSubscriptionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetActiveOrLatestSubscriptionRequest,
 *   !proto.subscription_service.GetSubscriptionResponse>}
 */
const methodInfo_SubscriptionService_GetOwnActiveOrLatestSubscription = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.GetSubscriptionResponse,
  /**
   * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetSubscriptionResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.GetSubscriptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.GetSubscriptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getOwnActiveOrLatestSubscription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnActiveOrLatestSubscription',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnActiveOrLatestSubscription,
      callback);
};


/**
 * @param {!proto.subscription_service.GetActiveOrLatestSubscriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.GetSubscriptionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getOwnActiveOrLatestSubscription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnActiveOrLatestSubscription',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnActiveOrLatestSubscription);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.PreviewOwnContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodDescriptor_SubscriptionService_PreviewOwnContract = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/PreviewOwnContract',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.PreviewOwnContractRequest,
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.PreviewOwnContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.PreviewOwnContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodInfo_SubscriptionService_PreviewOwnContract = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.PreviewOwnContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.PreviewOwnContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.previewOwnContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/PreviewOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_PreviewOwnContract,
      callback);
};


/**
 * @param {!proto.subscription_service.PreviewOwnContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ContractResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.previewOwnContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/PreviewOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_PreviewOwnContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.SignContractRequest,
 *   !proto.subscription_service.SignContractResponse>}
 */
const methodDescriptor_SubscriptionService_SignOwnContract = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/SignOwnContract',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.SignContractRequest,
  subscription_service_pb.SignContractResponse,
  /**
   * @param {!proto.subscription_service.SignContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.SignContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.SignContractRequest,
 *   !proto.subscription_service.SignContractResponse>}
 */
const methodInfo_SubscriptionService_SignOwnContract = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.SignContractResponse,
  /**
   * @param {!proto.subscription_service.SignContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.SignContractResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.SignContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.SignContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.SignContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.signOwnContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/SignOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_SignOwnContract,
      callback);
};


/**
 * @param {!proto.subscription_service.SignContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.SignContractResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.signOwnContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/SignOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_SignOwnContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetOwnContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodDescriptor_SubscriptionService_GetOwnContract = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetOwnContract',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetOwnContractRequest,
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.GetOwnContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetOwnContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodInfo_SubscriptionService_GetOwnContract = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.GetOwnContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetOwnContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getOwnContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnContract,
      callback);
};


/**
 * @param {!proto.subscription_service.GetOwnContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ContractResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getOwnContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodDescriptor_SubscriptionService_GetContract = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetContract',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetContractRequest,
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.GetContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetContractRequest,
 *   !proto.subscription_service.ContractResponse>}
 */
const methodInfo_SubscriptionService_GetContract = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ContractResponse,
  /**
   * @param {!proto.subscription_service.GetContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ContractResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetContract,
      callback);
};


/**
 * @param {!proto.subscription_service.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ContractResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetContract',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.PreviewOwnPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodDescriptor_SubscriptionService_PreviewOwnPickupReceipt = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/PreviewOwnPickupReceipt',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.PreviewOwnPickupReceiptRequest,
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.PreviewOwnPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodInfo_SubscriptionService_PreviewOwnPickupReceipt = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.PickupReceiptResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.PickupReceiptResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.previewOwnPickupReceipt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/PreviewOwnPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_PreviewOwnPickupReceipt,
      callback);
};


/**
 * @param {!proto.subscription_service.PreviewOwnPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.PickupReceiptResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.previewOwnPickupReceipt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/PreviewOwnPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_PreviewOwnPickupReceipt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetOwnPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodDescriptor_SubscriptionService_GetOwnPickupReceipt = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetOwnPickupReceipt',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetOwnPickupReceiptRequest,
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetOwnPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodInfo_SubscriptionService_GetOwnPickupReceipt = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.PickupReceiptResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.PickupReceiptResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getOwnPickupReceipt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnPickupReceipt,
      callback);
};


/**
 * @param {!proto.subscription_service.GetOwnPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.PickupReceiptResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getOwnPickupReceipt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetOwnPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetOwnPickupReceipt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodDescriptor_SubscriptionService_GetPickupReceipt = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetPickupReceipt',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetPickupReceiptRequest,
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.GetPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetPickupReceiptRequest,
 *   !proto.subscription_service.PickupReceiptResponse>}
 */
const methodInfo_SubscriptionService_GetPickupReceipt = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.PickupReceiptResponse,
  /**
   * @param {!proto.subscription_service.GetPickupReceiptRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.PickupReceiptResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.PickupReceiptResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.PickupReceiptResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getPickupReceipt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetPickupReceipt,
      callback);
};


/**
 * @param {!proto.subscription_service.GetPickupReceiptRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.PickupReceiptResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getPickupReceipt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetPickupReceipt',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetPickupReceipt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.ActivateRequest,
 *   !proto.subscription_service.ActivateResponse>}
 */
const methodDescriptor_SubscriptionService_Activate = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/Activate',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.ActivateRequest,
  subscription_service_pb.ActivateResponse,
  /**
   * @param {!proto.subscription_service.ActivateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ActivateResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.ActivateRequest,
 *   !proto.subscription_service.ActivateResponse>}
 */
const methodInfo_SubscriptionService_Activate = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ActivateResponse,
  /**
   * @param {!proto.subscription_service.ActivateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ActivateResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.ActivateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ActivateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ActivateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.activate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/Activate',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_Activate,
      callback);
};


/**
 * @param {!proto.subscription_service.ActivateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ActivateResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.activate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/Activate',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_Activate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.CreateWebReservationRequest,
 *   !proto.subscription_service.CreateWebReservationResponse>}
 */
const methodDescriptor_SubscriptionService_CreateWebReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/CreateWebReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.CreateWebReservationRequest,
  subscription_service_pb.CreateWebReservationResponse,
  /**
   * @param {!proto.subscription_service.CreateWebReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.CreateWebReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.CreateWebReservationRequest,
 *   !proto.subscription_service.CreateWebReservationResponse>}
 */
const methodInfo_SubscriptionService_CreateWebReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.CreateWebReservationResponse,
  /**
   * @param {!proto.subscription_service.CreateWebReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.CreateWebReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.CreateWebReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.CreateWebReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.CreateWebReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.createWebReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/CreateWebReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_CreateWebReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.CreateWebReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.CreateWebReservationResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.createWebReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/CreateWebReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_CreateWebReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.ListReservationsRequest,
 *   !proto.subscription_service.ListReservationsResponse>}
 */
const methodDescriptor_SubscriptionService_ListReservations = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/ListReservations',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.ListReservationsRequest,
  subscription_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.subscription_service.ListReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.ListReservationsRequest,
 *   !proto.subscription_service.ListReservationsResponse>}
 */
const methodInfo_SubscriptionService_ListReservations = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.subscription_service.ListReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ListReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ListReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.listReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/ListReservations',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_ListReservations,
      callback);
};


/**
 * @param {!proto.subscription_service.ListReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ListReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.listReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/ListReservations',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_ListReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.ListProgramsRequest,
 *   !proto.subscription_service.ListProgramsResponse>}
 */
const methodDescriptor_SubscriptionService_ListPrograms = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/ListPrograms',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.ListProgramsRequest,
  subscription_service_pb.ListProgramsResponse,
  /**
   * @param {!proto.subscription_service.ListProgramsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListProgramsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.ListProgramsRequest,
 *   !proto.subscription_service.ListProgramsResponse>}
 */
const methodInfo_SubscriptionService_ListPrograms = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.ListProgramsResponse,
  /**
   * @param {!proto.subscription_service.ListProgramsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.ListProgramsResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.ListProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.ListProgramsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.ListProgramsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.listPrograms =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/ListPrograms',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_ListPrograms,
      callback);
};


/**
 * @param {!proto.subscription_service.ListProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.ListProgramsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.listPrograms =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/ListPrograms',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_ListPrograms);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetReservationRequest,
 *   !proto.subscription_service.GetReservationResponse>}
 */
const methodDescriptor_SubscriptionService_GetReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetReservationRequest,
  subscription_service_pb.GetReservationResponse,
  /**
   * @param {!proto.subscription_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetReservationRequest,
 *   !proto.subscription_service.GetReservationResponse>}
 */
const methodInfo_SubscriptionService_GetReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.GetReservationResponse,
  /**
   * @param {!proto.subscription_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.GetReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.GetReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.GetReservationResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.CancelReservationRequest,
 *   !proto.subscription_service.CancelReservationResponse>}
 */
const methodDescriptor_SubscriptionService_CancelReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/CancelReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.CancelReservationRequest,
  subscription_service_pb.CancelReservationResponse,
  /**
   * @param {!proto.subscription_service.CancelReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.CancelReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.CancelReservationRequest,
 *   !proto.subscription_service.CancelReservationResponse>}
 */
const methodInfo_SubscriptionService_CancelReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.CancelReservationResponse,
  /**
   * @param {!proto.subscription_service.CancelReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.CancelReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.CancelReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.CancelReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.CancelReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.cancelReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/CancelReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_CancelReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.CancelReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.CancelReservationResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.cancelReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/CancelReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_CancelReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.UpdateReservationRequest,
 *   !proto.subscription_service.UpdateReservationResponse>}
 */
const methodDescriptor_SubscriptionService_UpdateReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/UpdateReservation',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.UpdateReservationRequest,
  subscription_service_pb.UpdateReservationResponse,
  /**
   * @param {!proto.subscription_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.UpdateReservationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.UpdateReservationRequest,
 *   !proto.subscription_service.UpdateReservationResponse>}
 */
const methodInfo_SubscriptionService_UpdateReservation = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.UpdateReservationResponse,
  /**
   * @param {!proto.subscription_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.UpdateReservationResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.UpdateReservationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.UpdateReservationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.updateReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_UpdateReservation,
      callback);
};


/**
 * @param {!proto.subscription_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.UpdateReservationResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.updateReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/UpdateReservation',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_UpdateReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.GetProgramRequest,
 *   !proto.subscription_service.GetProgramResponse>}
 */
const methodDescriptor_SubscriptionService_GetProgram = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/GetProgram',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.GetProgramRequest,
  subscription_service_pb.GetProgramResponse,
  /**
   * @param {!proto.subscription_service.GetProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.GetProgramRequest,
 *   !proto.subscription_service.GetProgramResponse>}
 */
const methodInfo_SubscriptionService_GetProgram = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.GetProgramResponse,
  /**
   * @param {!proto.subscription_service.GetProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.GetProgramResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.GetProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.GetProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.GetProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.getProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetProgram',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetProgram,
      callback);
};


/**
 * @param {!proto.subscription_service.GetProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.GetProgramResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.getProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/GetProgram',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_GetProgram);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.subscription_service.SetReservationProgramRequest,
 *   !proto.subscription_service.SetReservationProgramResponse>}
 */
const methodDescriptor_SubscriptionService_SetReservationProgram = new grpc.web.MethodDescriptor(
  '/gateway_service.SubscriptionService/SetReservationProgram',
  grpc.web.MethodType.UNARY,
  subscription_service_pb.SetReservationProgramRequest,
  subscription_service_pb.SetReservationProgramResponse,
  /**
   * @param {!proto.subscription_service.SetReservationProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.SetReservationProgramResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.subscription_service.SetReservationProgramRequest,
 *   !proto.subscription_service.SetReservationProgramResponse>}
 */
const methodInfo_SubscriptionService_SetReservationProgram = new grpc.web.AbstractClientBase.MethodInfo(
  subscription_service_pb.SetReservationProgramResponse,
  /**
   * @param {!proto.subscription_service.SetReservationProgramRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  subscription_service_pb.SetReservationProgramResponse.deserializeBinary
);


/**
 * @param {!proto.subscription_service.SetReservationProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.subscription_service.SetReservationProgramResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.subscription_service.SetReservationProgramResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.SubscriptionServiceClient.prototype.setReservationProgram =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.SubscriptionService/SetReservationProgram',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_SetReservationProgram,
      callback);
};


/**
 * @param {!proto.subscription_service.SetReservationProgramRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.subscription_service.SetReservationProgramResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.SubscriptionServicePromiseClient.prototype.setReservationProgram =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.SubscriptionService/SetReservationProgram',
      request,
      metadata || {},
      methodDescriptor_SubscriptionService_SetReservationProgram);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerSubscriptionPricingClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.CustomerSubscriptionPricingPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.spre.ListProgramPricingOptionsRequest,
 *   !proto.spre.ListProgramPricingOptionsResponse>}
 */
const methodDescriptor_CustomerSubscriptionPricing_ListProgramPricingOptions = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSubscriptionPricing/ListProgramPricingOptions',
  grpc.web.MethodType.UNARY,
  spre_pb.ListProgramPricingOptionsRequest,
  spre_pb.ListProgramPricingOptionsResponse,
  /**
   * @param {!proto.spre.ListProgramPricingOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  spre_pb.ListProgramPricingOptionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.spre.ListProgramPricingOptionsRequest,
 *   !proto.spre.ListProgramPricingOptionsResponse>}
 */
const methodInfo_CustomerSubscriptionPricing_ListProgramPricingOptions = new grpc.web.AbstractClientBase.MethodInfo(
  spre_pb.ListProgramPricingOptionsResponse,
  /**
   * @param {!proto.spre.ListProgramPricingOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  spre_pb.ListProgramPricingOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.spre.ListProgramPricingOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.spre.ListProgramPricingOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.spre.ListProgramPricingOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSubscriptionPricingClient.prototype.listProgramPricingOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSubscriptionPricing/ListProgramPricingOptions',
      request,
      metadata || {},
      methodDescriptor_CustomerSubscriptionPricing_ListProgramPricingOptions,
      callback);
};


/**
 * @param {!proto.spre.ListProgramPricingOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.spre.ListProgramPricingOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSubscriptionPricingPromiseClient.prototype.listProgramPricingOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSubscriptionPricing/ListProgramPricingOptions',
      request,
      metadata || {},
      methodDescriptor_CustomerSubscriptionPricing_ListProgramPricingOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.spre.QuoteRequest,
 *   !proto.spre.CreateIdResponse>}
 */
const methodDescriptor_CustomerSubscriptionPricing_CreateQuote = new grpc.web.MethodDescriptor(
  '/gateway_service.CustomerSubscriptionPricing/CreateQuote',
  grpc.web.MethodType.UNARY,
  spre_pb.QuoteRequest,
  spre_pb.CreateIdResponse,
  /**
   * @param {!proto.spre.QuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  spre_pb.CreateIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.spre.QuoteRequest,
 *   !proto.spre.CreateIdResponse>}
 */
const methodInfo_CustomerSubscriptionPricing_CreateQuote = new grpc.web.AbstractClientBase.MethodInfo(
  spre_pb.CreateIdResponse,
  /**
   * @param {!proto.spre.QuoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  spre_pb.CreateIdResponse.deserializeBinary
);


/**
 * @param {!proto.spre.QuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.spre.CreateIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.spre.CreateIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.CustomerSubscriptionPricingClient.prototype.createQuote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.CustomerSubscriptionPricing/CreateQuote',
      request,
      metadata || {},
      methodDescriptor_CustomerSubscriptionPricing_CreateQuote,
      callback);
};


/**
 * @param {!proto.spre.QuoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.spre.CreateIdResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.CustomerSubscriptionPricingPromiseClient.prototype.createQuote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.CustomerSubscriptionPricing/CreateQuote',
      request,
      metadata || {},
      methodDescriptor_CustomerSubscriptionPricing_CreateQuote);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.NotificationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.NotificationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification_service.SendDeepLinkRequest,
 *   !proto.notification_service.SendDeepLinkResponse>}
 */
const methodDescriptor_NotificationService_SendDeepLink = new grpc.web.MethodDescriptor(
  '/gateway_service.NotificationService/SendDeepLink',
  grpc.web.MethodType.UNARY,
  notification_service_pb.SendDeepLinkRequest,
  notification_service_pb.SendDeepLinkResponse,
  /**
   * @param {!proto.notification_service.SendDeepLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.SendDeepLinkResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.notification_service.SendDeepLinkRequest,
 *   !proto.notification_service.SendDeepLinkResponse>}
 */
const methodInfo_NotificationService_SendDeepLink = new grpc.web.AbstractClientBase.MethodInfo(
  notification_service_pb.SendDeepLinkResponse,
  /**
   * @param {!proto.notification_service.SendDeepLinkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.SendDeepLinkResponse.deserializeBinary
);


/**
 * @param {!proto.notification_service.SendDeepLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.notification_service.SendDeepLinkResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification_service.SendDeepLinkResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.NotificationServiceClient.prototype.sendDeepLink =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.NotificationService/SendDeepLink',
      request,
      metadata || {},
      methodDescriptor_NotificationService_SendDeepLink,
      callback);
};


/**
 * @param {!proto.notification_service.SendDeepLinkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification_service.SendDeepLinkResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.NotificationServicePromiseClient.prototype.sendDeepLink =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.NotificationService/SendDeepLink',
      request,
      metadata || {},
      methodDescriptor_NotificationService_SendDeepLink);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification_service.GetNotificationsRequest,
 *   !proto.notification_service.GetNotificationsResponse>}
 */
const methodDescriptor_NotificationService_GetNotifications = new grpc.web.MethodDescriptor(
  '/gateway_service.NotificationService/GetNotifications',
  grpc.web.MethodType.UNARY,
  notification_service_pb.GetNotificationsRequest,
  notification_service_pb.GetNotificationsResponse,
  /**
   * @param {!proto.notification_service.GetNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.GetNotificationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.notification_service.GetNotificationsRequest,
 *   !proto.notification_service.GetNotificationsResponse>}
 */
const methodInfo_NotificationService_GetNotifications = new grpc.web.AbstractClientBase.MethodInfo(
  notification_service_pb.GetNotificationsResponse,
  /**
   * @param {!proto.notification_service.GetNotificationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.GetNotificationsResponse.deserializeBinary
);


/**
 * @param {!proto.notification_service.GetNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.notification_service.GetNotificationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification_service.GetNotificationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.NotificationServiceClient.prototype.getNotifications =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.NotificationService/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_NotificationService_GetNotifications,
      callback);
};


/**
 * @param {!proto.notification_service.GetNotificationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification_service.GetNotificationsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.NotificationServicePromiseClient.prototype.getNotifications =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.NotificationService/GetNotifications',
      request,
      metadata || {},
      methodDescriptor_NotificationService_GetNotifications);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification_service.UpdateNotificationStatusRequest,
 *   !proto.notification_service.UpdateNotificationStatusResponse>}
 */
const methodDescriptor_NotificationService_UpdateNotificationStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.NotificationService/UpdateNotificationStatus',
  grpc.web.MethodType.UNARY,
  notification_service_pb.UpdateNotificationStatusRequest,
  notification_service_pb.UpdateNotificationStatusResponse,
  /**
   * @param {!proto.notification_service.UpdateNotificationStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.UpdateNotificationStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.notification_service.UpdateNotificationStatusRequest,
 *   !proto.notification_service.UpdateNotificationStatusResponse>}
 */
const methodInfo_NotificationService_UpdateNotificationStatus = new grpc.web.AbstractClientBase.MethodInfo(
  notification_service_pb.UpdateNotificationStatusResponse,
  /**
   * @param {!proto.notification_service.UpdateNotificationStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  notification_service_pb.UpdateNotificationStatusResponse.deserializeBinary
);


/**
 * @param {!proto.notification_service.UpdateNotificationStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.notification_service.UpdateNotificationStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification_service.UpdateNotificationStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.NotificationServiceClient.prototype.updateNotificationStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.NotificationService/UpdateNotificationStatus',
      request,
      metadata || {},
      methodDescriptor_NotificationService_UpdateNotificationStatus,
      callback);
};


/**
 * @param {!proto.notification_service.UpdateNotificationStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification_service.UpdateNotificationStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.NotificationServicePromiseClient.prototype.updateNotificationStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.NotificationService/UpdateNotificationStatus',
      request,
      metadata || {},
      methodDescriptor_NotificationService_UpdateNotificationStatus);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DocumentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DocumentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.GetOwnDocumentsRequest,
 *   !proto.document_service.GetOwnDocumentsResponse>}
 */
const methodDescriptor_DocumentService_GetOwnDocuments = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/GetOwnDocuments',
  grpc.web.MethodType.UNARY,
  document_service_pb.GetOwnDocumentsRequest,
  document_service_pb.GetOwnDocumentsResponse,
  /**
   * @param {!proto.document_service.GetOwnDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetOwnDocumentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.GetOwnDocumentsRequest,
 *   !proto.document_service.GetOwnDocumentsResponse>}
 */
const methodInfo_DocumentService_GetOwnDocuments = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.GetOwnDocumentsResponse,
  /**
   * @param {!proto.document_service.GetOwnDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetOwnDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.GetOwnDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.GetOwnDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.GetOwnDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.getOwnDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/GetOwnDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetOwnDocuments,
      callback);
};


/**
 * @param {!proto.document_service.GetOwnDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.GetOwnDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.getOwnDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/GetOwnDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetOwnDocuments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.GetOwnDocumentRequest,
 *   !proto.document_service.GetOwnDocumentResponse>}
 */
const methodDescriptor_DocumentService_GetOwnDocument = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/GetOwnDocument',
  grpc.web.MethodType.UNARY,
  document_service_pb.GetOwnDocumentRequest,
  document_service_pb.GetOwnDocumentResponse,
  /**
   * @param {!proto.document_service.GetOwnDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetOwnDocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.GetOwnDocumentRequest,
 *   !proto.document_service.GetOwnDocumentResponse>}
 */
const methodInfo_DocumentService_GetOwnDocument = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.GetOwnDocumentResponse,
  /**
   * @param {!proto.document_service.GetOwnDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetOwnDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.GetOwnDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.GetOwnDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.GetOwnDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.getOwnDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/GetOwnDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetOwnDocument,
      callback);
};


/**
 * @param {!proto.document_service.GetOwnDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.GetOwnDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.getOwnDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/GetOwnDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetOwnDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.UploadVehicleDocumentRequest,
 *   !proto.document_service.UploadVehicleDocumentResponse>}
 */
const methodDescriptor_DocumentService_UploadVehicleDocument = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/UploadVehicleDocument',
  grpc.web.MethodType.UNARY,
  document_service_pb.UploadVehicleDocumentRequest,
  document_service_pb.UploadVehicleDocumentResponse,
  /**
   * @param {!proto.document_service.UploadVehicleDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadVehicleDocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.UploadVehicleDocumentRequest,
 *   !proto.document_service.UploadVehicleDocumentResponse>}
 */
const methodInfo_DocumentService_UploadVehicleDocument = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.UploadVehicleDocumentResponse,
  /**
   * @param {!proto.document_service.UploadVehicleDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadVehicleDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.UploadVehicleDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.UploadVehicleDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.UploadVehicleDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.uploadVehicleDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadVehicleDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadVehicleDocument,
      callback);
};


/**
 * @param {!proto.document_service.UploadVehicleDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.UploadVehicleDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.uploadVehicleDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadVehicleDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadVehicleDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.UploadSingleCustomerInspectionImageRequest,
 *   !proto.document_service.UploadSingleCustomerInspectionImageResponse>}
 */
const methodDescriptor_DocumentService_UploadSingleCustomerInspectionImage = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/UploadSingleCustomerInspectionImage',
  grpc.web.MethodType.UNARY,
  document_service_pb.UploadSingleCustomerInspectionImageRequest,
  document_service_pb.UploadSingleCustomerInspectionImageResponse,
  /**
   * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadSingleCustomerInspectionImageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.UploadSingleCustomerInspectionImageRequest,
 *   !proto.document_service.UploadSingleCustomerInspectionImageResponse>}
 */
const methodInfo_DocumentService_UploadSingleCustomerInspectionImage = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.UploadSingleCustomerInspectionImageResponse,
  /**
   * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadSingleCustomerInspectionImageResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.UploadSingleCustomerInspectionImageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.UploadSingleCustomerInspectionImageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.uploadSingleCustomerInspectionImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadSingleCustomerInspectionImage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadSingleCustomerInspectionImage,
      callback);
};


/**
 * @param {!proto.document_service.UploadSingleCustomerInspectionImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.UploadSingleCustomerInspectionImageResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.uploadSingleCustomerInspectionImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadSingleCustomerInspectionImage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadSingleCustomerInspectionImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.UploadCustomerInspectionImageRequest,
 *   !proto.document_service.UploadCustomerInspectionImageResponse>}
 */
const methodDescriptor_DocumentService_UploadCustomerInspectionImage = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/UploadCustomerInspectionImage',
  grpc.web.MethodType.UNARY,
  document_service_pb.UploadCustomerInspectionImageRequest,
  document_service_pb.UploadCustomerInspectionImageResponse,
  /**
   * @param {!proto.document_service.UploadCustomerInspectionImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadCustomerInspectionImageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.UploadCustomerInspectionImageRequest,
 *   !proto.document_service.UploadCustomerInspectionImageResponse>}
 */
const methodInfo_DocumentService_UploadCustomerInspectionImage = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.UploadCustomerInspectionImageResponse,
  /**
   * @param {!proto.document_service.UploadCustomerInspectionImageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadCustomerInspectionImageResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.UploadCustomerInspectionImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.UploadCustomerInspectionImageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.UploadCustomerInspectionImageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.uploadCustomerInspectionImage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadCustomerInspectionImage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadCustomerInspectionImage,
      callback);
};


/**
 * @param {!proto.document_service.UploadCustomerInspectionImageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.UploadCustomerInspectionImageResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.uploadCustomerInspectionImage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadCustomerInspectionImage',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadCustomerInspectionImage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.UploadInsuranceCardRequest,
 *   !proto.document_service.UploadInsuranceCardResponse>}
 */
const methodDescriptor_DocumentService_UploadInsuranceCard = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/UploadInsuranceCard',
  grpc.web.MethodType.UNARY,
  document_service_pb.UploadInsuranceCardRequest,
  document_service_pb.UploadInsuranceCardResponse,
  /**
   * @param {!proto.document_service.UploadInsuranceCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadInsuranceCardResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.UploadInsuranceCardRequest,
 *   !proto.document_service.UploadInsuranceCardResponse>}
 */
const methodInfo_DocumentService_UploadInsuranceCard = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.UploadInsuranceCardResponse,
  /**
   * @param {!proto.document_service.UploadInsuranceCardRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.UploadInsuranceCardResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.UploadInsuranceCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.UploadInsuranceCardResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.UploadInsuranceCardResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.uploadInsuranceCard =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadInsuranceCard',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadInsuranceCard,
      callback);
};


/**
 * @param {!proto.document_service.UploadInsuranceCardRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.UploadInsuranceCardResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.uploadInsuranceCard =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/UploadInsuranceCard',
      request,
      metadata || {},
      methodDescriptor_DocumentService_UploadInsuranceCard);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.GetCustomerDocumentRequest,
 *   !proto.document_service.GetCustomerDocumentResponse>}
 */
const methodDescriptor_DocumentService_GetCustomerDocument = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/GetCustomerDocument',
  grpc.web.MethodType.UNARY,
  document_service_pb.GetCustomerDocumentRequest,
  document_service_pb.GetCustomerDocumentResponse,
  /**
   * @param {!proto.document_service.GetCustomerDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetCustomerDocumentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.GetCustomerDocumentRequest,
 *   !proto.document_service.GetCustomerDocumentResponse>}
 */
const methodInfo_DocumentService_GetCustomerDocument = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.GetCustomerDocumentResponse,
  /**
   * @param {!proto.document_service.GetCustomerDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetCustomerDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.GetCustomerDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.GetCustomerDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.GetCustomerDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.getCustomerDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/GetCustomerDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetCustomerDocument,
      callback);
};


/**
 * @param {!proto.document_service.GetCustomerDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.GetCustomerDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.getCustomerDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/GetCustomerDocument',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetCustomerDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.document_service.GetCustomerDocumentsRequest,
 *   !proto.document_service.GetCustomerDocumentsResponse>}
 */
const methodDescriptor_DocumentService_GetCustomerDocuments = new grpc.web.MethodDescriptor(
  '/gateway_service.DocumentService/GetCustomerDocuments',
  grpc.web.MethodType.UNARY,
  document_service_pb.GetCustomerDocumentsRequest,
  document_service_pb.GetCustomerDocumentsResponse,
  /**
   * @param {!proto.document_service.GetCustomerDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetCustomerDocumentsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.document_service.GetCustomerDocumentsRequest,
 *   !proto.document_service.GetCustomerDocumentsResponse>}
 */
const methodInfo_DocumentService_GetCustomerDocuments = new grpc.web.AbstractClientBase.MethodInfo(
  document_service_pb.GetCustomerDocumentsResponse,
  /**
   * @param {!proto.document_service.GetCustomerDocumentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  document_service_pb.GetCustomerDocumentsResponse.deserializeBinary
);


/**
 * @param {!proto.document_service.GetCustomerDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.document_service.GetCustomerDocumentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.document_service.GetCustomerDocumentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DocumentServiceClient.prototype.getCustomerDocuments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DocumentService/GetCustomerDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetCustomerDocuments,
      callback);
};


/**
 * @param {!proto.document_service.GetCustomerDocumentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.document_service.GetCustomerDocumentsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DocumentServicePromiseClient.prototype.getCustomerDocuments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DocumentService/GetCustomerDocuments',
      request,
      metadata || {},
      methodDescriptor_DocumentService_GetCustomerDocuments);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.NexusServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.NexusServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.nexus_service.GetCustomerRequest,
 *   !proto.nexus_service.GetCustomerResponse>}
 */
const methodDescriptor_NexusService_GetCustomer = new grpc.web.MethodDescriptor(
  '/gateway_service.NexusService/GetCustomer',
  grpc.web.MethodType.UNARY,
  nexus_service_pb.GetCustomerRequest,
  nexus_service_pb.GetCustomerResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  nexus_service_pb.GetCustomerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.nexus_service.GetCustomerRequest,
 *   !proto.nexus_service.GetCustomerResponse>}
 */
const methodInfo_NexusService_GetCustomer = new grpc.web.AbstractClientBase.MethodInfo(
  nexus_service_pb.GetCustomerResponse,
  /**
   * @param {!proto.nexus_service.GetCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  nexus_service_pb.GetCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.nexus_service.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.nexus_service.GetCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.nexus_service.GetCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.NexusServiceClient.prototype.getCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.NexusService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomer,
      callback);
};


/**
 * @param {!proto.nexus_service.GetCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.nexus_service.GetCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.NexusServicePromiseClient.prototype.getCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.NexusService/GetCustomer',
      request,
      metadata || {},
      methodDescriptor_NexusService_GetCustomer);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.BillingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.BillingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetOwnAccountBalanceRequest,
 *   !proto.billing_service.GetAccountBalanceResponse>}
 */
const methodDescriptor_BillingService_GetOwnAccountBalance = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnAccountBalance',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetOwnAccountBalanceRequest,
  billing_service_pb.GetAccountBalanceResponse,
  /**
   * @param {!proto.billing_service.GetOwnAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetOwnAccountBalanceRequest,
 *   !proto.billing_service.GetAccountBalanceResponse>}
 */
const methodInfo_BillingService_GetOwnAccountBalance = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetAccountBalanceResponse,
  /**
   * @param {!proto.billing_service.GetOwnAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetOwnAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnAccountBalance',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnAccountBalance,
      callback);
};


/**
 * @param {!proto.billing_service.GetOwnAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnAccountBalance',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListOwnInvoicesRequest,
 *   !proto.billing_service.ListInvoicesResponse>}
 */
const methodDescriptor_BillingService_ListOwnInvoices = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListOwnInvoices',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListOwnInvoicesRequest,
  billing_service_pb.ListInvoicesResponse,
  /**
   * @param {!proto.billing_service.ListOwnInvoicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListInvoicesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListOwnInvoicesRequest,
 *   !proto.billing_service.ListInvoicesResponse>}
 */
const methodInfo_BillingService_ListOwnInvoices = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListInvoicesResponse,
  /**
   * @param {!proto.billing_service.ListOwnInvoicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListInvoicesResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListOwnInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListInvoicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListInvoicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listOwnInvoices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnInvoices',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnInvoices,
      callback);
};


/**
 * @param {!proto.billing_service.ListOwnInvoicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListInvoicesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listOwnInvoices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnInvoices',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnInvoices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_CreateProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreateProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreateReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.CreateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_CreateProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.CreateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreateProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreateProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_CreateOwnProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreateOwnProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreateReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.CreateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_CreateOwnProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.CreateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createOwnProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOwnProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.CreateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createOwnProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOwnProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_UpdateOwnProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateOwnProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_UpdateOwnProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateOwnProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOwnProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateOwnProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOwnProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_UpdateProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_UpdateProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.UpdateReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_GetOwnProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_GetOwnProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodDescriptor_BillingService_GetProgramReservation = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetProgramReservation',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetReservationRequest,
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetReservationRequest,
 *   !proto.billing_service.Reservation>}
 */
const methodInfo_BillingService_GetProgramReservation = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Reservation,
  /**
   * @param {!proto.billing_service.GetReservationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Reservation.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Reservation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Reservation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getProgramReservation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetProgramReservation,
      callback);
};


/**
 * @param {!proto.billing_service.GetReservationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Reservation>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getProgramReservation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetProgramReservation',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetProgramReservation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListProgramsRequest,
 *   !proto.billing_service.ListProgramsResponse>}
 */
const methodDescriptor_BillingService_ListProgramOfferings = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListProgramOfferings',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListProgramsRequest,
  billing_service_pb.ListProgramsResponse,
  /**
   * @param {!proto.billing_service.ListProgramsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProgramsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListProgramsRequest,
 *   !proto.billing_service.ListProgramsResponse>}
 */
const methodInfo_BillingService_ListProgramOfferings = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListProgramsResponse,
  /**
   * @param {!proto.billing_service.ListProgramsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProgramsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListProgramsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListProgramsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listProgramOfferings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListProgramOfferings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProgramOfferings,
      callback);
};


/**
 * @param {!proto.billing_service.ListProgramsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListProgramsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listProgramOfferings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListProgramOfferings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProgramOfferings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.DetailsRequest,
 *   !proto.billing_service.PromotionDetails>}
 */
const methodDescriptor_BillingService_GetPromotionDetails = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetPromotionDetails',
  grpc.web.MethodType.UNARY,
  billing_service_pb.DetailsRequest,
  billing_service_pb.PromotionDetails,
  /**
   * @param {!proto.billing_service.DetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.PromotionDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.DetailsRequest,
 *   !proto.billing_service.PromotionDetails>}
 */
const methodInfo_BillingService_GetPromotionDetails = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.PromotionDetails,
  /**
   * @param {!proto.billing_service.DetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.PromotionDetails.deserializeBinary
);


/**
 * @param {!proto.billing_service.DetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.PromotionDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.PromotionDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getPromotionDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetPromotionDetails',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetPromotionDetails,
      callback);
};


/**
 * @param {!proto.billing_service.DetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.PromotionDetails>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getPromotionDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetPromotionDetails',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetPromotionDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RedemptionRequest,
 *   !proto.billing_service.RedemptionResponse>}
 */
const methodDescriptor_BillingService_RedeemPromotion = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RedeemPromotion',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RedemptionRequest,
  billing_service_pb.RedemptionResponse,
  /**
   * @param {!proto.billing_service.RedemptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.RedemptionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RedemptionRequest,
 *   !proto.billing_service.RedemptionResponse>}
 */
const methodInfo_BillingService_RedeemPromotion = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.RedemptionResponse,
  /**
   * @param {!proto.billing_service.RedemptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.RedemptionResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.RedemptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.RedemptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.RedemptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.redeemPromotion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RedeemPromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_RedeemPromotion,
      callback);
};


/**
 * @param {!proto.billing_service.RedemptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.RedemptionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.redeemPromotion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RedeemPromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_RedeemPromotion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ReferralCodeRequest,
 *   !proto.billing_service.ReferralCodeResponse>}
 */
const methodDescriptor_BillingService_GetReferralCode = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetReferralCode',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ReferralCodeRequest,
  billing_service_pb.ReferralCodeResponse,
  /**
   * @param {!proto.billing_service.ReferralCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ReferralCodeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ReferralCodeRequest,
 *   !proto.billing_service.ReferralCodeResponse>}
 */
const methodInfo_BillingService_GetReferralCode = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ReferralCodeResponse,
  /**
   * @param {!proto.billing_service.ReferralCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ReferralCodeResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ReferralCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ReferralCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ReferralCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getReferralCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetReferralCode',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetReferralCode,
      callback);
};


/**
 * @param {!proto.billing_service.ReferralCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ReferralCodeResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getReferralCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetReferralCode',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetReferralCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetAccountBalanceRequest,
 *   !proto.billing_service.GetAccountBalanceResponse>}
 */
const methodDescriptor_BillingService_GetAccountBalance = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetAccountBalance',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetAccountBalanceRequest,
  billing_service_pb.GetAccountBalanceResponse,
  /**
   * @param {!proto.billing_service.GetAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetAccountBalanceRequest,
 *   !proto.billing_service.GetAccountBalanceResponse>}
 */
const methodInfo_BillingService_GetAccountBalance = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetAccountBalanceResponse,
  /**
   * @param {!proto.billing_service.GetAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetAccountBalance,
      callback);
};


/**
 * @param {!proto.billing_service.GetAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListInvoicesResponse>}
 */
const methodDescriptor_BillingService_ListInvoices = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListInvoices',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListInvoicesResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListInvoicesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListInvoicesResponse>}
 */
const methodInfo_BillingService_ListInvoices = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListInvoicesResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListInvoicesResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListInvoicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListInvoicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listInvoices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListInvoices,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListInvoicesResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listInvoices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListInvoices',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListInvoices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreatePromotionRequest,
 *   !proto.billing_service.CreatePromotionResponse>}
 */
const methodDescriptor_BillingService_CreatePromotion = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreatePromotion',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreatePromotionRequest,
  billing_service_pb.CreatePromotionResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreatePromotionRequest,
 *   !proto.billing_service.CreatePromotionResponse>}
 */
const methodInfo_BillingService_CreatePromotion = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.CreatePromotionResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.CreatePromotionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.CreatePromotionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createPromotion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreatePromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreatePromotion,
      callback);
};


/**
 * @param {!proto.billing_service.CreatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.CreatePromotionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createPromotion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreatePromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreatePromotion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdatePromotionRequest,
 *   !proto.billing_service.UpdatePromotionResponse>}
 */
const methodDescriptor_BillingService_UpdatePromotion = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdatePromotion',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdatePromotionRequest,
  billing_service_pb.UpdatePromotionResponse,
  /**
   * @param {!proto.billing_service.UpdatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdatePromotionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdatePromotionRequest,
 *   !proto.billing_service.UpdatePromotionResponse>}
 */
const methodInfo_BillingService_UpdatePromotion = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.UpdatePromotionResponse,
  /**
   * @param {!proto.billing_service.UpdatePromotionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdatePromotionResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.UpdatePromotionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.UpdatePromotionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updatePromotion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdatePromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdatePromotion,
      callback);
};


/**
 * @param {!proto.billing_service.UpdatePromotionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.UpdatePromotionResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updatePromotion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdatePromotion',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdatePromotion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionsResponse>}
 */
const methodDescriptor_BillingService_ListPromotions = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListPromotions',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListPromotionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionsResponse>}
 */
const methodInfo_BillingService_ListPromotions = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListPromotionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListPromotionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListPromotionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listPromotions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListPromotions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListPromotions,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListPromotionsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listPromotions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListPromotions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListPromotions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListReservationsResponse>}
 */
const methodDescriptor_BillingService_ListProgramReservations = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListProgramReservations',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListReservationsResponse>}
 */
const methodInfo_BillingService_ListProgramReservations = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listProgramReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListProgramReservations',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProgramReservations,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listProgramReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListProgramReservations',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProgramReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListOwnProgramReservationsRequest,
 *   !proto.billing_service.ListReservationsResponse>}
 */
const methodDescriptor_BillingService_ListOwnProgramReservations = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListOwnProgramReservations',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListOwnProgramReservationsRequest,
  billing_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.billing_service.ListOwnProgramReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListOwnProgramReservationsRequest,
 *   !proto.billing_service.ListReservationsResponse>}
 */
const methodInfo_BillingService_ListOwnProgramReservations = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListReservationsResponse,
  /**
   * @param {!proto.billing_service.ListOwnProgramReservationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListReservationsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListOwnProgramReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListReservationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListReservationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listOwnProgramReservations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnProgramReservations',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnProgramReservations,
      callback);
};


/**
 * @param {!proto.billing_service.ListOwnProgramReservationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListReservationsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listOwnProgramReservations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnProgramReservations',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnProgramReservations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListProductsResponse>}
 */
const methodDescriptor_BillingService_ListProductOfferings = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListProductOfferings',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListProductsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProductsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListProductsResponse>}
 */
const methodInfo_BillingService_ListProductOfferings = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListProductsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListProductsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listProductOfferings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListProductOfferings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProductOfferings,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListProductsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listProductOfferings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListProductOfferings',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListProductOfferings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.AddToOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_AddToOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/AddToOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.AddToOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.AddToOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.AddToOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_AddToOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.AddToOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.AddToOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.addToOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/AddToOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddToOrder,
      callback);
};


/**
 * @param {!proto.billing_service.AddToOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.addToOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/AddToOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddToOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreatePromotionPartnerRequest,
 *   !proto.billing_service.CreatePromotionPartnerResponse>}
 */
const methodDescriptor_BillingService_CreatePromotionPartner = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreatePromotionPartner',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreatePromotionPartnerRequest,
  billing_service_pb.CreatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionPartnerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreatePromotionPartnerRequest,
 *   !proto.billing_service.CreatePromotionPartnerResponse>}
 */
const methodInfo_BillingService_CreatePromotionPartner = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.CreatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.CreatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreatePromotionPartnerResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.CreatePromotionPartnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.CreatePromotionPartnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createPromotionPartner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreatePromotionPartner,
      callback);
};


/**
 * @param {!proto.billing_service.CreatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.CreatePromotionPartnerResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createPromotionPartner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreatePromotionPartner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdatePromotionPartnerRequest,
 *   !proto.billing_service.UpdatePromotionPartnerResponse>}
 */
const methodDescriptor_BillingService_UpdatePromotionPartner = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdatePromotionPartner',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdatePromotionPartnerRequest,
  billing_service_pb.UpdatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.UpdatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdatePromotionPartnerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdatePromotionPartnerRequest,
 *   !proto.billing_service.UpdatePromotionPartnerResponse>}
 */
const methodInfo_BillingService_UpdatePromotionPartner = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.UpdatePromotionPartnerResponse,
  /**
   * @param {!proto.billing_service.UpdatePromotionPartnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdatePromotionPartnerResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.UpdatePromotionPartnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.UpdatePromotionPartnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updatePromotionPartner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdatePromotionPartner,
      callback);
};


/**
 * @param {!proto.billing_service.UpdatePromotionPartnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.UpdatePromotionPartnerResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updatePromotionPartner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdatePromotionPartner',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdatePromotionPartner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionPartnersResponse>}
 */
const methodDescriptor_BillingService_ListPromotionPartners = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListPromotionPartners',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListPromotionPartnersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionPartnersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListPromotionPartnersResponse>}
 */
const methodInfo_BillingService_ListPromotionPartners = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListPromotionPartnersResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListPromotionPartnersResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListPromotionPartnersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListPromotionPartnersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listPromotionPartners =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListPromotionPartners',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListPromotionPartners,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListPromotionPartnersResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listPromotionPartners =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListPromotionPartners',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListPromotionPartners);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreateFeeInvoiceRequest,
 *   !proto.billing_service.CreateFeeInvoiceResponse>}
 */
const methodDescriptor_BillingService_CreateFeeInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreateFeeInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreateFeeInvoiceRequest,
  billing_service_pb.CreateFeeInvoiceResponse,
  /**
   * @param {!proto.billing_service.CreateFeeInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreateFeeInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreateFeeInvoiceRequest,
 *   !proto.billing_service.CreateFeeInvoiceResponse>}
 */
const methodInfo_BillingService_CreateFeeInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.CreateFeeInvoiceResponse,
  /**
   * @param {!proto.billing_service.CreateFeeInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.CreateFeeInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreateFeeInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.CreateFeeInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.CreateFeeInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createFeeInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreateFeeInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateFeeInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.CreateFeeInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.CreateFeeInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createFeeInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreateFeeInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateFeeInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreateOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_CreateOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreateOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreateOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreateOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_CreateOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOrder,
      callback);
};


/**
 * @param {!proto.billing_service.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.CreateOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_CreateOwnOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/CreateOwnOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.CreateOwnOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.CreateOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.CreateOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_CreateOwnOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.CreateOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.CreateOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.createOwnOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOwnOrder,
      callback);
};


/**
 * @param {!proto.billing_service.CreateOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.createOwnOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/CreateOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_CreateOwnOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_UpdateOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.UpdateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_UpdateOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.UpdateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOrder,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_UpdateOwnOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateOwnOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateOwnOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.UpdateOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_UpdateOwnOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.UpdateOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateOwnOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOwnOrder,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateOwnOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateOwnOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.AddToOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_AddToOwnOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/AddToOwnOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.AddToOwnOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.AddToOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.AddToOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_AddToOwnOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.AddToOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.AddToOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.addToOwnOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/AddToOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddToOwnOrder,
      callback);
};


/**
 * @param {!proto.billing_service.AddToOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.addToOwnOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/AddToOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddToOwnOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RemoveFromOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_RemoveFromOwnOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RemoveFromOwnOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RemoveFromOwnOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.RemoveFromOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RemoveFromOwnOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_RemoveFromOwnOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.RemoveFromOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.RemoveFromOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.removeFromOwnOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveFromOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveFromOwnOrder,
      callback);
};


/**
 * @param {!proto.billing_service.RemoveFromOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.removeFromOwnOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveFromOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveFromOwnOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RemoveFromOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_RemoveFromOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RemoveFromOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RemoveFromOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.RemoveFromOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RemoveFromOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_RemoveFromOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.RemoveFromOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.RemoveFromOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.removeFromOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveFromOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveFromOrder,
      callback);
};


/**
 * @param {!proto.billing_service.RemoveFromOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.removeFromOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveFromOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveFromOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetOwnLatestDraftOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_GetOwnLatestDraftOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnLatestDraftOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetOwnLatestDraftOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetOwnLatestDraftOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_GetOwnLatestDraftOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnLatestDraftOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnLatestDraftOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnLatestDraftOrder,
      callback);
};


/**
 * @param {!proto.billing_service.GetOwnLatestDraftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnLatestDraftOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnLatestDraftOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnLatestDraftOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetLatestDraftOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_GetLatestDraftOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetLatestDraftOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetLatestDraftOrderRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.GetLatestDraftOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetLatestDraftOrderRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_GetLatestDraftOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.GetLatestDraftOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetLatestDraftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getLatestDraftOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetLatestDraftOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetLatestDraftOrder,
      callback);
};


/**
 * @param {!proto.billing_service.GetLatestDraftOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getLatestDraftOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetLatestDraftOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetLatestDraftOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.DeleteOrderRequest,
 *   !proto.billing_service.DeleteOrderResponse>}
 */
const methodDescriptor_BillingService_DeleteOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/DeleteOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.DeleteOrderRequest,
  billing_service_pb.DeleteOrderResponse,
  /**
   * @param {!proto.billing_service.DeleteOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.DeleteOrderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.DeleteOrderRequest,
 *   !proto.billing_service.DeleteOrderResponse>}
 */
const methodInfo_BillingService_DeleteOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.DeleteOrderResponse,
  /**
   * @param {!proto.billing_service.DeleteOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.DeleteOrderResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.DeleteOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.DeleteOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.deleteOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_DeleteOrder,
      callback);
};


/**
 * @param {!proto.billing_service.DeleteOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.DeleteOrderResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.deleteOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/DeleteOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_DeleteOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.DeleteOwnOrderRequest,
 *   !proto.billing_service.DeleteOrderResponse>}
 */
const methodDescriptor_BillingService_DeleteOwnOrder = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/DeleteOwnOrder',
  grpc.web.MethodType.UNARY,
  billing_service_pb.DeleteOwnOrderRequest,
  billing_service_pb.DeleteOrderResponse,
  /**
   * @param {!proto.billing_service.DeleteOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.DeleteOrderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.DeleteOwnOrderRequest,
 *   !proto.billing_service.DeleteOrderResponse>}
 */
const methodInfo_BillingService_DeleteOwnOrder = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.DeleteOrderResponse,
  /**
   * @param {!proto.billing_service.DeleteOwnOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.DeleteOrderResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.DeleteOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.DeleteOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.DeleteOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.deleteOwnOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/DeleteOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_DeleteOwnOrder,
      callback);
};


/**
 * @param {!proto.billing_service.DeleteOwnOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.DeleteOrderResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.deleteOwnOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/DeleteOwnOrder',
      request,
      metadata || {},
      methodDescriptor_BillingService_DeleteOwnOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ConvertOrderToInvoiceRequest,
 *   !proto.billing_service.ConvertOrderToInvoiceResponse>}
 */
const methodDescriptor_BillingService_ConvertOrderToInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ConvertOrderToInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ConvertOrderToInvoiceRequest,
  billing_service_pb.ConvertOrderToInvoiceResponse,
  /**
   * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ConvertOrderToInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ConvertOrderToInvoiceRequest,
 *   !proto.billing_service.ConvertOrderToInvoiceResponse>}
 */
const methodInfo_BillingService_ConvertOrderToInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ConvertOrderToInvoiceResponse,
  /**
   * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ConvertOrderToInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ConvertOrderToInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ConvertOrderToInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.convertOrderToInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ConvertOrderToInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_ConvertOrderToInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.ConvertOrderToInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ConvertOrderToInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.convertOrderToInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ConvertOrderToInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_ConvertOrderToInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ConvertOwnOrderToInvoiceRequest,
 *   !proto.billing_service.ConvertOrderToInvoiceResponse>}
 */
const methodDescriptor_BillingService_ConvertOwnOrderToInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ConvertOwnOrderToInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ConvertOwnOrderToInvoiceRequest,
  billing_service_pb.ConvertOrderToInvoiceResponse,
  /**
   * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ConvertOrderToInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ConvertOwnOrderToInvoiceRequest,
 *   !proto.billing_service.ConvertOrderToInvoiceResponse>}
 */
const methodInfo_BillingService_ConvertOwnOrderToInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ConvertOrderToInvoiceResponse,
  /**
   * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ConvertOrderToInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ConvertOrderToInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ConvertOrderToInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.convertOwnOrderToInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ConvertOwnOrderToInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_ConvertOwnOrderToInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.ConvertOwnOrderToInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ConvertOrderToInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.convertOwnOrderToInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ConvertOwnOrderToInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_ConvertOwnOrderToInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.PayInvoiceRequest,
 *   !proto.billing_service.PayInvoiceResponse>}
 */
const methodDescriptor_BillingService_PayInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/PayInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.PayInvoiceRequest,
  billing_service_pb.PayInvoiceResponse,
  /**
   * @param {!proto.billing_service.PayInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.PayInvoiceResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.PayInvoiceRequest,
 *   !proto.billing_service.PayInvoiceResponse>}
 */
const methodInfo_BillingService_PayInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.PayInvoiceResponse,
  /**
   * @param {!proto.billing_service.PayInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.PayInvoiceResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.PayInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.PayInvoiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.PayInvoiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.payInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/PayInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_PayInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.PayInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.PayInvoiceResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.payInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/PayInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_PayInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetBillingStatusRequest,
 *   !proto.billing_service.GetBillingStatusResponse>}
 */
const methodDescriptor_BillingService_GetBillingStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetBillingStatus',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetBillingStatusRequest,
  billing_service_pb.GetBillingStatusResponse,
  /**
   * @param {!proto.billing_service.GetBillingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetBillingStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetBillingStatusRequest,
 *   !proto.billing_service.GetBillingStatusResponse>}
 */
const methodInfo_BillingService_GetBillingStatus = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetBillingStatusResponse,
  /**
   * @param {!proto.billing_service.GetBillingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetBillingStatusResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetBillingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetBillingStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetBillingStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getBillingStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetBillingStatus',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetBillingStatus,
      callback);
};


/**
 * @param {!proto.billing_service.GetBillingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetBillingStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getBillingStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetBillingStatus',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetBillingStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetOwnBillingStatusRequest,
 *   !proto.billing_service.GetBillingStatusResponse>}
 */
const methodDescriptor_BillingService_GetOwnBillingStatus = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnBillingStatus',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetOwnBillingStatusRequest,
  billing_service_pb.GetBillingStatusResponse,
  /**
   * @param {!proto.billing_service.GetOwnBillingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetBillingStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetOwnBillingStatusRequest,
 *   !proto.billing_service.GetBillingStatusResponse>}
 */
const methodInfo_BillingService_GetOwnBillingStatus = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetBillingStatusResponse,
  /**
   * @param {!proto.billing_service.GetOwnBillingStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetBillingStatusResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetOwnBillingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetBillingStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetBillingStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnBillingStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnBillingStatus',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnBillingStatus,
      callback);
};


/**
 * @param {!proto.billing_service.GetOwnBillingStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetBillingStatusResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnBillingStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnBillingStatus',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnBillingStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetDriveOffInvoiceRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodDescriptor_BillingService_GetDriveOffInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetDriveOffInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetDriveOffInvoiceRequest,
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.GetDriveOffInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetDriveOffInvoiceRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodInfo_BillingService_GetDriveOffInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.GetDriveOffInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetDriveOffInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.InvoiceFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.InvoiceFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getDriveOffInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetDriveOffInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetDriveOffInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.GetDriveOffInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.InvoiceFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getDriveOffInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetDriveOffInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetDriveOffInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetOwnDriveOffInvoiceRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodDescriptor_BillingService_GetOwnDriveOffInvoice = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnDriveOffInvoice',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetOwnDriveOffInvoiceRequest,
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetOwnDriveOffInvoiceRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodInfo_BillingService_GetOwnDriveOffInvoice = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.InvoiceFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.InvoiceFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnDriveOffInvoice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnDriveOffInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnDriveOffInvoice,
      callback);
};


/**
 * @param {!proto.billing_service.GetOwnDriveOffInvoiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.InvoiceFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnDriveOffInvoice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnDriveOffInvoice',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnDriveOffInvoice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetSubscriptionByIdRequest,
 *   !proto.billing_service.Subscription>}
 */
const methodDescriptor_BillingService_GetSubscriptionById = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetSubscriptionById',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetSubscriptionByIdRequest,
  billing_service_pb.Subscription,
  /**
   * @param {!proto.billing_service.GetSubscriptionByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Subscription.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetSubscriptionByIdRequest,
 *   !proto.billing_service.Subscription>}
 */
const methodInfo_BillingService_GetSubscriptionById = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.Subscription,
  /**
   * @param {!proto.billing_service.GetSubscriptionByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.Subscription.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetSubscriptionByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.Subscription)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.Subscription>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getSubscriptionById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetSubscriptionById',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetSubscriptionById,
      callback);
};


/**
 * @param {!proto.billing_service.GetSubscriptionByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.Subscription>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getSubscriptionById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetSubscriptionById',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetSubscriptionById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListSubscriptionsResponse>}
 */
const methodDescriptor_BillingService_ListSubscriptions = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListSubscriptions',
  grpc.web.MethodType.UNARY,
  web_pb.SearchParams,
  billing_service_pb.ListSubscriptionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListSubscriptionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.web.SearchParams,
 *   !proto.billing_service.ListSubscriptionsResponse>}
 */
const methodInfo_BillingService_ListSubscriptions = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListSubscriptionsResponse,
  /**
   * @param {!proto.web.SearchParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListSubscriptionsResponse.deserializeBinary
);


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListSubscriptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListSubscriptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listSubscriptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListSubscriptions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListSubscriptions,
      callback);
};


/**
 * @param {!proto.web.SearchParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListSubscriptionsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listSubscriptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListSubscriptions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListSubscriptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListOwnSubscriptionsRequest,
 *   !proto.billing_service.ListSubscriptionsResponse>}
 */
const methodDescriptor_BillingService_ListOwnSubscriptions = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListOwnSubscriptions',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListOwnSubscriptionsRequest,
  billing_service_pb.ListSubscriptionsResponse,
  /**
   * @param {!proto.billing_service.ListOwnSubscriptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListSubscriptionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListOwnSubscriptionsRequest,
 *   !proto.billing_service.ListSubscriptionsResponse>}
 */
const methodInfo_BillingService_ListOwnSubscriptions = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListSubscriptionsResponse,
  /**
   * @param {!proto.billing_service.ListOwnSubscriptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListSubscriptionsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListOwnSubscriptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListSubscriptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListSubscriptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listOwnSubscriptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnSubscriptions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnSubscriptions,
      callback);
};


/**
 * @param {!proto.billing_service.ListOwnSubscriptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListSubscriptionsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listOwnSubscriptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListOwnSubscriptions',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListOwnSubscriptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.ListRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodDescriptor_BillingService_ListRecurringBillableItems = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/ListRecurringBillableItems',
  grpc.web.MethodType.UNARY,
  billing_service_pb.ListRecurringBillableItemsRequest,
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.ListRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.ListRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodInfo_BillingService_ListRecurringBillableItems = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.ListRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.ListRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListRecurringBillableItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListRecurringBillableItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.listRecurringBillableItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/ListRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListRecurringBillableItems,
      callback);
};


/**
 * @param {!proto.billing_service.ListRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListRecurringBillableItemsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.listRecurringBillableItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/ListRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_ListRecurringBillableItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodDescriptor_BillingService_UpdateRecurringBillableItems = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateRecurringBillableItems',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateRecurringBillableItemsRequest,
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodInfo_BillingService_UpdateRecurringBillableItems = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListRecurringBillableItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListRecurringBillableItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateRecurringBillableItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateRecurringBillableItems,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListRecurringBillableItemsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateRecurringBillableItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateRecurringBillableItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.AddRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodDescriptor_BillingService_AddRecurringBillableItems = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/AddRecurringBillableItems',
  grpc.web.MethodType.UNARY,
  billing_service_pb.AddRecurringBillableItemsRequest,
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.AddRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.AddRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodInfo_BillingService_AddRecurringBillableItems = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.AddRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.AddRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListRecurringBillableItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListRecurringBillableItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.addRecurringBillableItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/AddRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddRecurringBillableItems,
      callback);
};


/**
 * @param {!proto.billing_service.AddRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListRecurringBillableItemsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.addRecurringBillableItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/AddRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddRecurringBillableItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RemoveRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodDescriptor_BillingService_RemoveRecurringBillableItems = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RemoveRecurringBillableItems',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RemoveRecurringBillableItemsRequest,
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RemoveRecurringBillableItemsRequest,
 *   !proto.billing_service.ListRecurringBillableItemsResponse>}
 */
const methodInfo_BillingService_RemoveRecurringBillableItems = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.ListRecurringBillableItemsResponse,
  /**
   * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.ListRecurringBillableItemsResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.ListRecurringBillableItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.ListRecurringBillableItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.removeRecurringBillableItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveRecurringBillableItems,
      callback);
};


/**
 * @param {!proto.billing_service.RemoveRecurringBillableItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.ListRecurringBillableItemsResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.removeRecurringBillableItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveRecurringBillableItems',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveRecurringBillableItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_GetMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_GetMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.GetMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.GetOwnMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_GetOwnMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/GetOwnMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.GetOwnMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.GetOwnMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_GetOwnMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.getOwnMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.GetOwnMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.getOwnMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/GetOwnMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_GetOwnMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_UpdateMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_UpdateMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.AddMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_AddMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/AddMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.AddMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.AddMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_AddMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.addMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/AddMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.AddMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.addMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/AddMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_AddMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RemoveMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_RemoveMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RemoveMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RemoveMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RemoveMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_RemoveMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.removeMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.RemoveMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.removeMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodDescriptor_BillingService_RemoveOwnMileageRecurringBillableItem = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/RemoveOwnMileageRecurringBillableItem',
  grpc.web.MethodType.UNARY,
  billing_service_pb.RemoveOwnMileageRecurringBillableItemRequest,
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest,
 *   !proto.billing_service.GetMileageRecurringBillableItemResponse>}
 */
const methodInfo_BillingService_RemoveOwnMileageRecurringBillableItem = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.GetMileageRecurringBillableItemResponse,
  /**
   * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.GetMileageRecurringBillableItemResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.GetMileageRecurringBillableItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.GetMileageRecurringBillableItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.removeOwnMileageRecurringBillableItem =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveOwnMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveOwnMileageRecurringBillableItem,
      callback);
};


/**
 * @param {!proto.billing_service.RemoveOwnMileageRecurringBillableItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.GetMileageRecurringBillableItemResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.removeOwnMileageRecurringBillableItem =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/RemoveOwnMileageRecurringBillableItem',
      request,
      metadata || {},
      methodDescriptor_BillingService_RemoveOwnMileageRecurringBillableItem);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.UpdateRecurringBillingDayRequest,
 *   !proto.billing_service.UpdateRecurringBillingDayResponse>}
 */
const methodDescriptor_BillingService_UpdateRecurringBillingDay = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/UpdateRecurringBillingDay',
  grpc.web.MethodType.UNARY,
  billing_service_pb.UpdateRecurringBillingDayRequest,
  billing_service_pb.UpdateRecurringBillingDayResponse,
  /**
   * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdateRecurringBillingDayResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.UpdateRecurringBillingDayRequest,
 *   !proto.billing_service.UpdateRecurringBillingDayResponse>}
 */
const methodInfo_BillingService_UpdateRecurringBillingDay = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.UpdateRecurringBillingDayResponse,
  /**
   * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.UpdateRecurringBillingDayResponse.deserializeBinary
);


/**
 * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.UpdateRecurringBillingDayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.UpdateRecurringBillingDayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.updateRecurringBillingDay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateRecurringBillingDay',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateRecurringBillingDay,
      callback);
};


/**
 * @param {!proto.billing_service.UpdateRecurringBillingDayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.UpdateRecurringBillingDayResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.updateRecurringBillingDay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/UpdateRecurringBillingDay',
      request,
      metadata || {},
      methodDescriptor_BillingService_UpdateRecurringBillingDay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.PurchaseOneTimeMileageRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodDescriptor_BillingService_PurchaseOneTimeMileage = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/PurchaseOneTimeMileage',
  grpc.web.MethodType.UNARY,
  billing_service_pb.PurchaseOneTimeMileageRequest,
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.PurchaseOneTimeMileageRequest,
 *   !proto.billing_service.InvoiceFE>}
 */
const methodInfo_BillingService_PurchaseOneTimeMileage = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.InvoiceFE,
  /**
   * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.InvoiceFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.InvoiceFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.InvoiceFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.purchaseOneTimeMileage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/PurchaseOneTimeMileage',
      request,
      metadata || {},
      methodDescriptor_BillingService_PurchaseOneTimeMileage,
      callback);
};


/**
 * @param {!proto.billing_service.PurchaseOneTimeMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.InvoiceFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.purchaseOneTimeMileage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/PurchaseOneTimeMileage',
      request,
      metadata || {},
      methodDescriptor_BillingService_PurchaseOneTimeMileage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.billing_service.PurchaseRecurringMileageRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodDescriptor_BillingService_PurchaseRecurringMileage = new grpc.web.MethodDescriptor(
  '/gateway_service.BillingService/PurchaseRecurringMileage',
  grpc.web.MethodType.UNARY,
  billing_service_pb.PurchaseRecurringMileageRequest,
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.PurchaseRecurringMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.billing_service.PurchaseRecurringMileageRequest,
 *   !proto.billing_service.OrderFE>}
 */
const methodInfo_BillingService_PurchaseRecurringMileage = new grpc.web.AbstractClientBase.MethodInfo(
  billing_service_pb.OrderFE,
  /**
   * @param {!proto.billing_service.PurchaseRecurringMileageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  billing_service_pb.OrderFE.deserializeBinary
);


/**
 * @param {!proto.billing_service.PurchaseRecurringMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.billing_service.OrderFE)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.billing_service.OrderFE>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.BillingServiceClient.prototype.purchaseRecurringMileage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.BillingService/PurchaseRecurringMileage',
      request,
      metadata || {},
      methodDescriptor_BillingService_PurchaseRecurringMileage,
      callback);
};


/**
 * @param {!proto.billing_service.PurchaseRecurringMileageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.billing_service.OrderFE>}
 *     Promise that resolves to the response
 */
proto.gateway_service.BillingServicePromiseClient.prototype.purchaseRecurringMileage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.BillingService/PurchaseRecurringMileage',
      request,
      metadata || {},
      methodDescriptor_BillingService_PurchaseRecurringMileage);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DemoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.gateway_service.DemoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.demo_service.GetLatestSMSContentRequest,
 *   !proto.demo_service.GetLatestSMSContentResponse>}
 */
const methodDescriptor_DemoService_GetLatestSMSContent = new grpc.web.MethodDescriptor(
  '/gateway_service.DemoService/GetLatestSMSContent',
  grpc.web.MethodType.UNARY,
  demo_service_pb.GetLatestSMSContentRequest,
  demo_service_pb.GetLatestSMSContentResponse,
  /**
   * @param {!proto.demo_service.GetLatestSMSContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  demo_service_pb.GetLatestSMSContentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.demo_service.GetLatestSMSContentRequest,
 *   !proto.demo_service.GetLatestSMSContentResponse>}
 */
const methodInfo_DemoService_GetLatestSMSContent = new grpc.web.AbstractClientBase.MethodInfo(
  demo_service_pb.GetLatestSMSContentResponse,
  /**
   * @param {!proto.demo_service.GetLatestSMSContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  demo_service_pb.GetLatestSMSContentResponse.deserializeBinary
);


/**
 * @param {!proto.demo_service.GetLatestSMSContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.demo_service.GetLatestSMSContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.demo_service.GetLatestSMSContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DemoServiceClient.prototype.getLatestSMSContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DemoService/GetLatestSMSContent',
      request,
      metadata || {},
      methodDescriptor_DemoService_GetLatestSMSContent,
      callback);
};


/**
 * @param {!proto.demo_service.GetLatestSMSContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.demo_service.GetLatestSMSContentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DemoServicePromiseClient.prototype.getLatestSMSContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DemoService/GetLatestSMSContent',
      request,
      metadata || {},
      methodDescriptor_DemoService_GetLatestSMSContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.demo_service.GetAppReviewSMSContentRequest,
 *   !proto.demo_service.GetLatestSMSContentResponse>}
 */
const methodDescriptor_DemoService_GetAppReviewSMSContent = new grpc.web.MethodDescriptor(
  '/gateway_service.DemoService/GetAppReviewSMSContent',
  grpc.web.MethodType.UNARY,
  demo_service_pb.GetAppReviewSMSContentRequest,
  demo_service_pb.GetLatestSMSContentResponse,
  /**
   * @param {!proto.demo_service.GetAppReviewSMSContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  demo_service_pb.GetLatestSMSContentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.demo_service.GetAppReviewSMSContentRequest,
 *   !proto.demo_service.GetLatestSMSContentResponse>}
 */
const methodInfo_DemoService_GetAppReviewSMSContent = new grpc.web.AbstractClientBase.MethodInfo(
  demo_service_pb.GetLatestSMSContentResponse,
  /**
   * @param {!proto.demo_service.GetAppReviewSMSContentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  demo_service_pb.GetLatestSMSContentResponse.deserializeBinary
);


/**
 * @param {!proto.demo_service.GetAppReviewSMSContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.demo_service.GetLatestSMSContentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.demo_service.GetLatestSMSContentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.gateway_service.DemoServiceClient.prototype.getAppReviewSMSContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/gateway_service.DemoService/GetAppReviewSMSContent',
      request,
      metadata || {},
      methodDescriptor_DemoService_GetAppReviewSMSContent,
      callback);
};


/**
 * @param {!proto.demo_service.GetAppReviewSMSContentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.demo_service.GetLatestSMSContentResponse>}
 *     Promise that resolves to the response
 */
proto.gateway_service.DemoServicePromiseClient.prototype.getAppReviewSMSContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/gateway_service.DemoService/GetAppReviewSMSContent',
      request,
      metadata || {},
      methodDescriptor_DemoService_GetAppReviewSMSContent);
};


module.exports = proto.gateway_service;

